export const ATTACHMENTS_INIT = 'ATTACHMENTS_INIT'
export const ATTACHMENTS_SEARCH = 'ATTACHMENTS_SEARCH'
export const ATTACHMENTS_SEARCH_CHANGED = 'ATTACHMENTS_SEARCH_CHANGED'
export const ATTACHMENTS_PAGE_CHANGE = 'ATTACHMENTS_PAGE_CHANGE'
export const ATTACHMENTS_PAGE_CHANGED = 'ATTACHMENTS_PAGE_CHANGED'
export const ATTACHMENTS_FETCH_REQUEST = 'ATTACHMENTS_FETCH_REQUEST'
export const ATTACHMENTS_FETCH_SUCCESS = 'ATTACHMENTS_FETCH_SUCCESS'
export const ATTACHMENTS_FETCH_FAILED = 'ATTACHMENTS_FETCH_FAILED'
export const ATTACHMENTS_TOGGLE = 'ATTACHMENTS_TOGGLE'
export const ATTACHMENTS_TOGGLE_AND_LOAD_USAGE = 'ATTACHMENTS_TOGGLE_AND_LOAD_USAGE'
export const ATTACHMENTS_USAGE_SUCCESS = 'ATTACHMENTS_USAGE_SUCCESS'
export const ATTACHMENTS_ADD_FILE = 'ATTACHMENTS_ADD_FILE'
export const ATTACHMENTS_UPLOAD_ENQUEUED = 'ATTACHMENTS_UPLOAD_ENQUEUED'
export const ATTACHMENTS_UPLOAD_REQUEST = 'ATTACHMENTS_UPLOAD_REQUEST'
export const ATTACHMENTS_UPLOAD_SUCCESS = 'ATTACHMENTS_UPLOAD_SUCCESS'
export const ATTACHMENTS_UPLOAD_FAILED = 'ATTACHMENTS_UPLOAD_FAILED'
export const ATTACHMENTS_UPLOAD_STARTED = 'ATTACHMENTS_UPLOAD_STARTED'
export const ATTACHMENTS_UPLOAD_CANCEL = 'ATTACHMENTS_UPLOAD_CANCEL'
export const ATTACHMENTS_UPLOAD_CANCELED = 'ATTACHMENTS_UPLOAD_CANCELED'
export const ATTACHMENTS_UPLOAD_PROGRESS = 'ATTACHMENTS_UPLOAD_PROGRESS'
export const ATTACHMENTS_ADD_PREVIEW = 'ATTACHMENTS_ADD_PREVIEW'
export const ATTACHMENT_GENERATE_PREVIEW = 'ATTACHMENT_GENERATE_PREVIEW'
export const ATTACHMENTS_MAX_PARALLEL_UPLOADS = 2
export const ATTACHMENTS_DELETE_REQUEST = 'ATTACHMENTS_DELETE_REQUEST'
export const ATTACHMENTS_DELETE_SUCCESS = 'ATTACHMENTS_DELETE_SUCCESS'
export const ATTACHMENTS_DELETE_FAILED = 'ATTACHMENTS_DELETE_FAILED'
export const ATTACHMENTS_UPDATE_REQUEST = 'ATTACHMENTS_UPDATE_REQUEST'
export const ATTACHMENTS_UPDATE_SUCCESS = 'ATTACHMENTS_UPDATE_SUCCESS'
export const ATTACHMENTS_UPDATE_FAILED = 'ATTACHMENTS_UPDATE_FAILED'
export const ATTACHMENTS_UPDATE_FILE_REQUEST = 'ATTACHMENTS_UPDATE_FILE_REQUEST'
export const ATTACHMENTS_UPDATE_FILE_SUCCESS = 'ATTACHMENTS_UPDATE_FILE_SUCCESS'
export const ATTACHMENTS_UPDATE_FILE_FAILED = 'ATTACHMENTS_UPDATE_FILE_FAILED'
export const ATTACHMENTS_EDIT = 'ATTACHMENTS_EDIT'
export const ATTACHMENTS_SET_MULTI_SELECT = 'ATTACHMENTS_SET_MULTI_SELECT'
export const ATTACHMENTS_SET_SELECTION = 'ATTACHMENTS_SET_SELECTION'
export const ATTACHMENT_COLLECTION_SET = 'ATTACHMENT_COLLECTION_SET'
export const ATTACHMENT_COLLECTION_ADD_ITEM = 'ATTACHMENT_COLLECTION_ADD_ITEM'
export const ATTACHMENT_COLLECTION_REMOVE_ITEM = 'ATTACHMENT_COLLECTION_REMOVE_ITEM'
export const ATTACHMENT_COLLECTION_MOVE_ITEM = 'ATTACHMENT_COLLECTION_MOVE_ITEM'
