import React from 'react'
import PropTypes from 'prop-types'
import Download from './Download'
import Pager from 'react-pager'

import styles from './download-list.module.scss'

const MyPager = ({ totalPages, currentPage, onClickPage }) => {
  if (totalPages > 1) {
    return (
      <div>
        <Pager
          total={totalPages}
          current={currentPage - 1}
          visiblePages={10}
          titles={{ first: '|<', last: '>|' }}
          onPageChanged={onClickPage}
        />
      </div>
    )
  }
  return null
}

MyPager.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClickPage: PropTypes.func.isRequired
}

const DownloadList = ({
  downloads,
  selectedDownloadId,
  toggleDownload,
  pagination,
  onClickPage
}) => (
  <div className="download-list">
    {downloads.map(download => (
      <Download
        selected={selectedDownloadId === download.id ? 'selected' : ''}
        key={download.id}
        {...download}
        toggleDownload={toggleDownload}
      />
    ))}
    <footer className={`${styles.footer}`}>
      <MyPager {...pagination} onClickPage={onClickPage} />
    </footer>
  </div>
)

DownloadList.propTypes = {
  downloads: PropTypes.array.isRequired,
  selectedDownloadId: PropTypes.number,
  toggleDownload: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  onClickPage: PropTypes.func.isRequired
}

export default DownloadList
