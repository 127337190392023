
import { put, takeLatest } from 'redux-saga/effects'
import { ATTACHMENTS_SEARCH } from '../constants'
import { load } from '../actions'

// worker Saga: will be fired on ATTACHMENTS_SEARCH actions
function* searchAttachments (action) {

  yield put(load(1, action.q, true))
}

/*
  searchSaga
  Does not allow concurrent searches of attachments
*/
export default function* searchSaga () {
  yield takeLatest(ATTACHMENTS_SEARCH, searchAttachments)
}
