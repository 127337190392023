import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Page from '../components/Page'
import { selectNodes, selectSelectedId } from '../selectors'
import { moveNode, toggleNode } from '../actions'

const mapStateToProps = createSelector(
  selectNodes(),
  selectSelectedId(),
  (nodes, selectedId) => ({ nodes, selectedId })
)

const mapDispatchToProps = (dispacth) => {
  return {
    moveNode: (id, idx) => dispacth(moveNode(id, idx)),
    toggleNode: (id) => dispacth(toggleNode(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
