import {
  ATTACHMENT_COLLECTION_SET,
  ATTACHMENT_COLLECTION_ADD_ITEM,
  ATTACHMENT_COLLECTION_REMOVE_ITEM,
  ATTACHMENT_COLLECTION_MOVE_ITEM
} from './constants'
import update from 'immutability-helper'

const initialState = {
  collections: {}
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case ATTACHMENT_COLLECTION_SET:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.id]: action.attachments
        }
      }
    case ATTACHMENT_COLLECTION_ADD_ITEM:
      const items = state.collections[action.id].slice().push(action.attachment)
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.id]: items
        }
      }
    case ATTACHMENT_COLLECTION_REMOVE_ITEM: {
      const items = state.collections[action.id].slice()
      const idx = items.findIndex(a => a.id === action.attachmentId)
      if (idx >= 0) {
        items.splice(idx, 1)
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.id]: items
        }
      }
    }
    case ATTACHMENT_COLLECTION_MOVE_ITEM: {
      const { id, oldIndex, newIndex } = action
      let items = state.collections[id].slice()

      update(items, {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, items[oldIndex]],
        ],
      })

      return {
        ...state,
        collections: {
          ...state.collections,
          [id]: items
        }
      }
    }
    default:
      return state
  }
}

export default reducer
