import {
  PAGE_INIT,
  PAGE_ADD_NODE,
  PAGE_MOVE_NODE,
  PAGE_REMOVE_NODE,
  PAGE_SELECT_NODE,
  PAGE_TOGGLE_NODE,
  PAGE_DESELECT,
  PAGE_UPDATE_NODE,
  PAGE_TOGGLE_COLOR,
  PAGE_UPDATE_NODE_BODY,
  PAGE_SAVE_REQUEST,
  PAGE_SAVE_SUCCESS,
  PAGE_SAVE_FAILED,
  PAGE_CHANGE_WIDTH,
  PAGE_MOVE_GALLERY_ITEM,
  PAGE_REMOVE_GALLERY_ITEM,
  DOWNLOADS_FETCH_REQUEST,
  DOWNLOADS_FETCH_SUCCESS,
  DOWNLOADS_FETCH_FAILED,
  DOWNLOADS_TOGGLE,
  DOWNLOADS_COMMIT_SELECTED,
  LINK_REMOVE
} from './constants'

const initialState = {
  pageId: 0,
  title: '',
  nodes: [],
  selectedId: 0,
  deletedIds: [],
  width: 100,
  submitting: false,
  err: null,
  downloads: [],
  downloadsLoading: false,
  selectedDownloads: [],
  selectedDownloadId: 0,
  downloadsErr: null,
  downloadsPagination: {
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    totalEntries: 0,
    nextPage: null,
    previousPage: null
  }
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case PAGE_INIT:
      return {
        ...state,
        pageId: action.pageId,
        title: action.title,
        nodes: action.nodes
      }
    case PAGE_CHANGE_WIDTH:
      const width = Math.min(Math.max(state.width + action.d, 20), 100)
      return {
        ...state,
        width
      }
    case PAGE_ADD_NODE: {
      let { selectedId, nodes } = state
      if (selectedId <= 0) {
        return {
          ...state,
          nodes: [...nodes, { ...action.node }]
        }
      } else {
        nodes = [...nodes]
        const idx = nodes.findIndex(n => n.id === selectedId)
        nodes.splice(idx + 1, 0, { ...action.node })
        return {
          ...state,
          nodes: nodes
        }
      }
    }
    case PAGE_MOVE_NODE:
      let nodes = state.nodes.slice()
      const node = nodes[action.oldIdx]
      nodes.splice(action.oldIdx, 1)
      nodes.splice(action.newIdx, 0, node)

      return {
        ...state,
        nodes
      }
    case PAGE_MOVE_GALLERY_ITEM: {
      let nodes = state.nodes.slice()
      const { selectedId } = state
      const idx = nodes.findIndex(n => n.id === selectedId)
      const node = nodes[idx]
      const attachments = node.attachments.slice()

      const attachment = attachments[action.dragIndex]

      attachments.splice(action.dragIndex, 1)
      attachments.splice(action.hoverIndex, 0, attachment)

      nodes[idx] = { ...node, attachments: attachments }

      return {
        ...state,
        nodes
      }
    }
    case PAGE_REMOVE_GALLERY_ITEM: {
      let nodes = state.nodes.slice()
      const { selectedId } = state
      const idx = nodes.findIndex(n => n.id === selectedId)
      const node = nodes[idx]
      const attachments = node.attachments.slice()
      const aIdx = attachments.findIndex(a => a.id === action.attachmentId)
      attachments.splice(aIdx, 1)

      nodes[idx] = { ...node, attachments: attachments }

      return {
        ...state,
        nodes
      }
    }
    case PAGE_REMOVE_NODE: {
      let idx = state.nodes.findIndex(n => n.id === action.id)
      const nodes = state.nodes.slice()
      nodes.splice(idx, 1)

      const deletedIds = state.deletedIds.slice()
      if (action.id > 0) {
        deletedIds.push(action.id)
      }

      return {
        ...state,
        nodes,
        deletedIds
      }
    }
    case PAGE_DESELECT:
      return {
        ...state,
        selectedId: 0
      }
    case PAGE_SELECT_NODE: {
      return {
        ...state,
        selectedId: action.id
      }
    }
    case PAGE_TOGGLE_NODE:
      const selectedId = action.id === state.selectedId ? 0 : action.id
      return {
        ...state,
        selectedId
      }
    case PAGE_UPDATE_NODE_BODY:
    case PAGE_TOGGLE_COLOR:
    case PAGE_UPDATE_NODE: {
      const idx = state.nodes.findIndex(n => n.id === action.node.id)
      return {
        ...state,
        nodes: [
          ...state.nodes.slice(0, idx),
          { ...state.nodes[idx], ...action.node },
          ...state.nodes.slice(idx + 1)
        ]
      }
    }
    case LINK_REMOVE: {
      const idx = state.nodes.findIndex(n => n.id == action.id)

      if (~idx) {

        let nodes = [...state.nodes]
        let n = { ...state.nodes[idx] };
        delete (n.link)
        nodes.splice(idx, 1, n)

        return {
          ...state,
          nodes
        }
      }

      return state;
    }
    case PAGE_SAVE_REQUEST:
      return {
        ...state,
        submitting: true,
        err: null
      }
    case PAGE_SAVE_SUCCESS:
      return {
        ...state,
        nodes: action.nodes.slice(),
        selectedId: 0,
        deletedIds: [],
        submitting: false
      }
    case PAGE_SAVE_FAILED:
      return {
        ...state,
        submitting: false,
        err: action.err
      }
    case DOWNLOADS_FETCH_REQUEST: {
      return { ...state, downloadsLoading: true }
    }
    case DOWNLOADS_FETCH_SUCCESS: {
      return {
        ...state,
        downloadsLoading: false,
        downloads: action.downloads.slice(),
        downloadsPagination: { ...action.meta.pagination }
      }
    }
    case DOWNLOADS_FETCH_FAILED: {
      return { ...state, downloadsLoading: false, downloadsErr: action.err }
    }
    case DOWNLOADS_TOGGLE: {
      const selectedDownloadId = action.id === state.selectedDownloadId ? 0 : action.id
      return {
        ...state,
        selectedDownloadId
      }
    }
    case DOWNLOADS_COMMIT_SELECTED: {
      const { selectedId } = state
      const idx = state.nodes.findIndex(n => n.id === selectedId)
      return {
        ...state,
        nodes: [
          ...state.nodes.slice(0, idx),
          { ...state.nodes[idx], download: action.download },
          ...state.nodes.slice(idx + 1)
        ]
      }
    }
    default:
      return state
  }
}

export default reducer
