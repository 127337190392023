import ReactOnRails from 'react-on-rails';
import attachmentsStore from '../bundles/admin/Attachments/store'
import pageStore from '../bundles/admin/PageBuilder/store'
// import "@hotwired/turbo-rails"
// // Turbo.session.drive = false
// window.Turbo = Turbo

import {
  AttachmentChooser,
  AttachmentsApp,
  AttachmentCollectionChooser,
  TokenInput,
  PageBuilder
} from '../bundles/admin/apps';

// ReactOnRails.setOptions({ turbo: true })

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  AttachmentsApp,
  AttachmentChooser,
  AttachmentCollectionChooser,
  TokenInput,
  PageBuilder
});


ReactOnRails.registerStore({
  attachmentsStore,
  pageStore
})

