import { combineReducers, applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'

import pageReducer from './reducer'
import linksReducer from './linksReducer'
import attachmentsReducer from '../Attachments/reducer'
import { initPage, initLinks } from './actions'

import sagas from './sagas'
import attachmentsSagas from '../Attachments/sagas'

const logger = createLogger({
  level: 'info',
  collapsed: true
})

const rootReducer = combineReducers({
  page: pageReducer,
  links: linksReducer,
  attachments: attachmentsReducer,
  form: formReducer
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const enhancer = compose(
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(sagaMiddleware)
    : applyMiddleware(sagaMiddleware),
  window.devToolsExtension ? window.devToolsExtension() : noop => noop
)

export default function initStore (initData, railsContext) {
  const { pageId, title, nodes, links } = initData

  let state = rootReducer(undefined, initPage(pageId, title, nodes))
  state = rootReducer(state, initLinks(links))
  // const store = configureStore(rootReducer, state, enhancer)
  const store = configureStore({ reducer: rootReducer, preloadedState: state, enhancers: [enhancer] })
  // run all default exported sagas
  sagas.map(sagaMiddleware.run)
  attachmentsSagas.map(sagaMiddleware.run)

  if (module.hot) {
    module.hot.accept(
      './reducer',
      () => store.replaceReducer(require('./reducer')) // eslint-disable-line global-require
    )
  }
  return store
}
