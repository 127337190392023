import React from 'react'
import Select from 'react-select'
import PropTypes from "prop-types"

const FSelect = ({ input, handleSubmit, options, linkValue, meta: { touched, error } }) => {
  const handleBlur = e => e.preventDefault();
  return (
    <Select
      {...input}
      searchable={true}
      onBlur={handleBlur}

      onChange={(option) => {
        option && input.onChange(option.value)
        option && setTimeout(handleSubmit, 100)

      }}
      options={options}
    />

  )
}

FSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  linkValue: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

export default FSelect
