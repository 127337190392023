import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const DownloadForm = ({ node, onClickDownload }) => {
  const onClick = () => onClickDownload(node)
  const className = classnames('form-group form-download-select', {
    'download-present': node.thumbUrl !== undefined
  })
  return (
    <div className={className}>
      <a className="btn btn-default" onClick={onClick}>
        <i className="fa fa-download" /> Download wählen
      </a>
      {node.download && (
        <div>
          <img src={node.download.thumbUrl} onClick={onClick} />
          <h4>{node.download.titleDe}</h4>
        </div>
      )}
    </div>
  )
}

DownloadForm.propTypes = {
  onClickDownload: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired
}

export default DownloadForm
