import {
  ATTACHMENTS_INIT,
  ATTACHMENTS_SEARCH,
  ATTACHMENTS_SEARCH_CHANGED,
  ATTACHMENTS_PAGE_CHANGE,
  ATTACHMENTS_FETCH_REQUEST,
  ATTACHMENTS_TOGGLE,
  ATTACHMENTS_TOGGLE_AND_LOAD_USAGE,
  ATTACHMENTS_USAGE_SUCCESS,
  ATTACHMENTS_ADD_FILE,
  // ATTACHMENTS_UPLOAD_REQUEST,
  ATTACHMENTS_UPLOAD_STARTED,
  ATTACHMENTS_UPLOAD_CANCEL,
  ATTACHMENTS_UPLOAD_PROGRESS,
  ATTACHMENTS_DELETE_REQUEST,
  ATTACHMENTS_UPDATE_REQUEST,
  ATTACHMENTS_UPDATE_FILE_REQUEST,
  ATTACHMENTS_EDIT,
  ATTACHMENTS_SET_MULTI_SELECT,
  ATTACHMENTS_SET_SELECTION,
  ATTACHMENT_COLLECTION_SET,
  ATTACHMENT_COLLECTION_ADD_ITEM,
  ATTACHMENT_COLLECTION_REMOVE_ITEM
} from './constants'

// init from passed store
export const initAttachments = (attachments, total, perPage, collections = {}) => ({
  type: ATTACHMENTS_INIT,
  attachments,
  total,
  perPage,
  collections
})

export const toggle = (id) => ({
  type: ATTACHMENTS_TOGGLE,
  id
})

export const toggleAndLoadUsage = (id) => ({
  type: ATTACHMENTS_TOGGLE_AND_LOAD_USAGE,
  id
})

export const usageReceived = (id, data) => ({
  type: ATTACHMENTS_USAGE_SUCCESS,
  id,
  data
})

// search
export const search = (q = {}, typeahead = false) => ({
  type: ATTACHMENTS_SEARCH,
  q,
  typeahead
})

// search
export const searchChanged = (q = {}, typeahead = false) => ({
  type: ATTACHMENTS_SEARCH_CHANGED,
  q,
  typeahead
})

export const reset = () => search({}, false)

export const addFiles = (files, autoSelect = false) => ({
  type: ATTACHMENTS_ADD_FILE,
  files,
  autoSelect
})

export const uploadStarted = (id, cancel) => ({
  type: ATTACHMENTS_UPLOAD_STARTED,
  id,
  cancel

})

export const cancelUpload = (id) => ({
  type: ATTACHMENTS_UPLOAD_CANCEL,
  id
})

export const uploadProgress = (id, progress) => ({
  type: ATTACHMENTS_UPLOAD_PROGRESS,
  id,
  progress
})

export const destroy = (id) => ({
  type: ATTACHMENTS_DELETE_REQUEST,
  id
})

export const edit = (id) => ({
  type: ATTACHMENTS_EDIT,
  id
})

export const update = (attachment) => ({
  type: ATTACHMENTS_UPDATE_REQUEST,
  attachment
})

export const updateFile = (id, file) => ({
  type: ATTACHMENTS_UPDATE_FILE_REQUEST,
  id,
  file
})

// load Page
export const loadPage = (page) => ({
  type: ATTACHMENTS_PAGE_CHANGE,
  page
})

// load
export const load = (page = 1, q = {}, typeahead = false) => ({
  type: ATTACHMENTS_FETCH_REQUEST,
  page,
  q,
  typeahead
})

export const setMultiSelect = (multiSelect, multiSelectAppend = false) => ({
  type: ATTACHMENTS_SET_MULTI_SELECT,
  multiSelect,
  multiSelectAppend
})

export const setSelection = (attachments) => ({
  type: ATTACHMENTS_SET_SELECTION,
  attachments
})

// Collections
export const setCollection = (id, attachments) => ({
  type: ATTACHMENT_COLLECTION_SET,
  id,
  attachments
})

export const collectionAddItem = (id, attachment) => ({
  type: ATTACHMENT_COLLECTION_ADD_ITEM,
  id,
  attachment
})

export const collectionRemoveItem = (id, attachmentId) => ({
  type: ATTACHMENT_COLLECTION_REMOVE_ITEM,
  id,
  attachmentId
})

export const collectionMoveItem = (id, newIndex, oldIndex) => ({
  type: ATTACHMENT_COLLECTION_REMOVE_ITEM,
  id,
  newIndex, oldIndex
})
