import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { selectCollection } from '../../selectors'
import AttachmentCollection from '../../components/AttachmentCollection'
import AttachmentChooser from '../AttachmentChooser'

import {
  load,
  setMultiSelect,
  setSelection,
  collectionRemoveItem,
  collectionMoveItem
} from '../../actions'

const modalId = 'AttachmentCollectionLauncher-modal'

class AttachmentCollectionLauncher extends Component {
  static propTypes = {
    objName: PropTypes.string.isRequired,
    objId: PropTypes.string.isRequired,
    idParam: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    didComplete: PropTypes.func.isRequired,
    collectionMoveItem: PropTypes.func.isRequired,
    selectedAttachments: PropTypes.arrayOf(PropTypes.object)
  }

  constructor(props) {
    super(props)
    this.onClick = (e) => this._onClick(e)
    this.state = {}
  }

  _onClick (e) {
    e.preventDefault()
    this.props.onClick(this.props.selectedAttachments)
    this.forceUpdate()
  }

  render () {
    const {
      selectedAttachments,
      objName,
      idParam,
      objId,
      onClickDelete,
      didComplete,
      collectionMoveItem
    } = this.props

    return (
      <div>
        <AttachmentCollection
          collectionMoveItem={collectionMoveItem}
          onClickDelete={onClickDelete}
          attachments={selectedAttachments}
          objName={objName}
          idParam={idParam} />
        <a onClick={this.onClick} className='btn btn-default'>
          <i className='fa fa-plus' />
          {' '}
          Bild hinzufügen
        </a>
        <AttachmentChooser objId={objId} modalId={modalId} didComplete={didComplete} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const collection = selectCollection(ownProps.objId)(state)
  return {
    selectedAttachments: collection
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClickDelete: (attId) => dispatch(collectionRemoveItem(ownProps.objId, attId)),
    collectionMoveItem: (nIdx, oIdx) => dispatch(collectionMoveItem(ownProps.objId, nIdx, oIdx)),
    onClick: (atms) => {
      dispatch(load())
      dispatch(setMultiSelect(true, true))
      dispatch(setSelection(atms))

      $(`#${modalId}`).modal('show')
    },
    didComplete: () => $(`#${modalId}`).modal('hide')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentCollectionLauncher)
