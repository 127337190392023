/**
*
* AttachmentsPage
*
*/
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import AttachmentListContainer from '../AttachmentListContainer'
import AttachmentForm from '../AttachmentForm'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

import { setMultiSelect, toggle } from '../../actions'
import { selectedAttachment, selectSelectedUsage } from '../../selectors'

import styles from './AttachmentsPageContainer.module.scss'

class AttachmentsPage extends Component { // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    selectedAttachment: PropTypes.object,
    setSingleSelectionMode: PropTypes.func.isRequired,
    usage: PropTypes.array
  }

  componentDidMount () {
    this.props.setSingleSelectionMode(false)
  }

  render () {
    // setTimeout(() => {
    //   $('.col-md-3').stick_in_parent({
    //     offset_top: $('.navbar').height() + 12,
    //     bottoming: false,
    //     parent: '.col-md-12'
    //   })
    // }, 10)



    const { selectedAttachment } = this.props


    const toggleDrawer = () => {
      if (selectedAttachment) {
        this.props.toggle(selectedAttachment.id)
      }
    }

    return (
      <div className={`${styles.AttachmentsPage}`}>
        <div>
          <AttachmentListContainer editable loadUsage />
        </div>
        <Drawer
          open={selectedAttachment != null}
          onClose={toggleDrawer}
          direction='right'
          className=''
          size={550}
          duration={200}
          zIndex={100000}

        >
          <div className={styles.drawerContent}>
            {selectedAttachment && <AttachmentForm usage={this.props.usage} />}
          </div>
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  selectedAttachment(),
  selectSelectedUsage(),
  (selectedAttachment, usage) => ({ selectedAttachment, usage })
)

const mapDispatchToProps = (dispatch) => {
  return {
    toggle: id => dispatch(toggle(id)),
    setSingleSelectionMode: () => dispatch(setMultiSelect(false))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsPage)
