/**
*
* AttachmentsFilterContainer
*
*/
import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
// import styles from './AttachmentsFilterContainer.scss'

import {
  search,
  searchChanged
} from '../../actions'

const renderField = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>
        <i className='fa fa-search' />
        {' '}
      </label>
      <input
        {...input}
        className='form-control'
        placeholder={label}
        autoComplete='off'
        type={type || 'text'}
        onChange={(data) => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const renderRadio = ({ input, label, handleSubmit, radioValue, on, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <div className='checkbox'>
        <label>
          <input
            {...input}
            label=''
            placeholder={label}
            type='radio'
            value={radioValue}
            onChange={(data) => {
              input.onChange(data)
              setTimeout(handleSubmit, 1)
            }}
          />
          {' '}
          {label}
        </label>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}

renderRadio.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  radioValue: PropTypes.string,
  on: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderSelect = ({ input, label, handleSubmit, options, icon, meta: { touched, error, submitting } }) => (
  <div className='form-group'>
    <label className='label-o'>
      <i className={`fa fa-${icon}`} />
      {' '}
    </label>
    <select {...input}
      className='form-select'
      onChange={event => {
        input.onChange(event.target.value)
        setTimeout(handleSubmit)
      }}>
      {options}
    </select>
  </div>
)

renderSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  icon: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

class AttachmentsFilterContainer extends Component { // eslint-disable-line react/prefer-stateless-function

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    searchChanged: PropTypes.func.isRequired,
    initialized: PropTypes.bool
  }

  componentDidMount () {
    this.props.searchChanged({ folder: folder() })
  }

  render () {
    const {
      search,
      handleSubmit,
      // initialized
    } = this.props

    // const fs = this.props.getFormState()
    // debugger

    return (
      <form onSubmit={handleSubmit(search)}>
        <Field
          label='Titel oder Dateiname'
          component={renderField}
          handleSubmit={handleSubmit(search)}
          name='file_or_title_de_cont'
          autoComplete='off'
        />
        {' '}
        <Field
          name='content_type_in'
          label='Type'
          icon='file-o'
          component={renderSelect}
          handleSubmit={handleSubmit(search)}
          options={
            [
              <option key='t-0' value=''>Alle</option>,
              <option key='t-1' value='image'>Image</option>,
              <option key='t-2' value='pdf'>PDFs</option>,
              <option key='t-3' value='zip'>ZIPs</option>
            ]
          }
        />
        {' '}
        <Field
          name='folder'
          label='Folder'
          icon='folder-o'
          component={renderSelect}
          handleSubmit={handleSubmit(search)}
          options={
            [
              <option key='f-0' value=''>Alle</option>,
              <option key='f-1' value='kulturkreis'>kulturkreis</option>,
              <option key='f-2' value='arsviva'>arsviva</option>,
              <option key='f-3' value='jahresring'>jahresring</option>
            ]
          }
        />
      </form>
    )
  }
}

const folder = () => {
  // debugger
  const p = location.hostname.split('.')
  return p[0] === 'www' ? p[1] : p[0]
}

const mapStateToProps = (dispatch) => ({
  initialValues: { content_type_in: 'image', folder: folder() }
})

const mapDispatchToProps = (dispatch) => {
  return {
    searchChanged: (values) => dispatch(searchChanged(values)),
    search: (values, ctx) => {
      dispatch(search(values, true))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'attachmentFilters'  // a unique identifier for this form
  })(AttachmentsFilterContainer)
)
