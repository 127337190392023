import { createSelector } from 'reselect'

const selectAttachments = () => state => state.attachments

const selectAttachment = id =>
  createSelector(selectAttachments(), state => state.attachments.find(a => a.id === id))

const selectAttachmentsSelection = () =>
  createSelector(selectAttachments(), state => state.selected)

const selectedAttachment = () =>
  createSelector(selectAttachmentsSelection(), selection => selection[0])

const selectUploadProgress = id => createSelector(selectAttachments(), state => state.progress[id])

const selectPage = () => createSelector(selectAttachments(), state => state.page)

const selectQ = id => createSelector(selectAttachments(), state => state.q)

const selectCollection = id => createSelector(selectAttachments(), state => state.collections[id])

const selectUsages = () => createSelector(selectAttachments(), state => state.usage)

const selectUsageById = id => createSelector(selectUsages(), usage => usage[id])

const selectSelectedUsage = () =>
  createSelector(
    selectUsages(),
    selectedAttachment(),
    (usage, attachment) => attachment && usage[attachment.id]
  )

export {
  selectAttachments,
  selectAttachment,
  selectAttachmentsSelection,
  selectedAttachment,
  selectUploadProgress,
  selectQ,
  selectPage,
  selectCollection,
  selectUsages,
  selectUsageById,
  selectSelectedUsage
}
