
import React, { useCallback, useState, useEffect } from 'react'
import update from 'immutability-helper'
import PropTypes from "prop-types"
import { Card } from './Card.js'
import AttachmentPreview from '..//AttachmentPreview'
import style from './styles.module.scss'
import { at } from 'lodash'

function AttachmentCollection (props) {

  const { objName, onClickDelete } = props


  const [attachments, seAttachments] = useState([...props.attachments])
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    seAttachments((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      }),
    )
  }, [])

  useEffect(() => seAttachments([...props.attachments]), [props.attachments])


  const renderCard = useCallback((attachment, index) => {
    return (
      <Card
        key={attachment.id}
        index={index}
        id={attachment.id}
        text={attachment.text}
        moveCard={moveCard}
      >
        <input type='hidden' name={`${objName}[]`} value={attachment.id} />
        <div className={style.item}>
          <div className='preview-container'>
            <AttachmentPreview {...attachment} />
          </div>
          <a onClick={() => onClickDelete(attachment.id)} className='attachment-collection-delete'>
            <i className='fa fa-trash-o' />
            <span className='hidden'>Entfernen</span>
          </a>
        </div>
      </Card>
    )
  }, [])

  return (
    <div className={style.AttachmentCollection}>
      <input type='hidden' name={`${objName}[]`} value='' />
      <div className='attachment-collection-wrap'>
        {/* {JSON.stringify(attachments_)} */}
        {attachments.map((attachment, i) => renderCard(attachment, i))}
      </div>
    </div>
  )
}

AttachmentCollection.propTypes = {
  collectionMoveItem: PropTypes.func,
  attachments: PropTypes.arrayOf(PropTypes.object),
  objName: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

export default AttachmentCollection

