import { put, call, takeLatest, delay } from "redux-saga/effects";
import {
  ATTACHMENTS_FETCH_REQUEST,
  ATTACHMENTS_FETCH_SUCCESS,
  ATTACHMENTS_FETCH_FAILED
} from "../constants";
import { fetch } from "../api";

// worker Saga: will be fired on ATTACHMENTS_FETCH_REQUESTED actions
function* fetchAttachments (action) {
  try {
    /*
      If typeahead delay to allow the user to finsih typing
    */
    if (action.typeahead) {
      yield delay(350);
    }

    const q = {
      content_type_in: "image",
      ...action.q
    };

    const { data } = yield call(fetch, action.page, q);
    yield put({ type: ATTACHMENTS_FETCH_SUCCESS, ...data });
  } catch (e) {
    yield put({ type: ATTACHMENTS_FETCH_FAILED, message: e.message });
  }
}
/*
  fetchSaga

  Does not allow concurrent fetches of attachments
*/
export default function* fetchSaga () {
  yield takeLatest(ATTACHMENTS_FETCH_REQUEST, fetchAttachments);
}
