export const PAGE_INIT = 'PAGE_INIT'
export const PAGE_ADD_NODE = 'PAGE_ADD_NODE'
export const PAGE_MOVE_NODE = 'PAGE_MOVE_NODE'
export const PAGE_REMOVE_NODE = 'PAGE_REMOVE_NODE'
export const PAGE_REMOVE_SELECTED_NODE = 'PAGE_REMOVE_SELECTED_NODE'
export const PAGE_SELECT_NODE = 'PAGE_SELECT_NODE'
export const PAGE_TOGGLE_NODE = 'PAGE_TOGGLE_NODE'
export const PAGE_REQUEST_TOGGLE_NODE = 'PAGE_REQUEST_TOGGLE_NODE'
export const PAGE_DESELECT = 'PAGE_DESELECT'
export const PAGE_UPDATE_NODE = 'PAGE_UPDATE_NODE'
export const PAGE_TOGGLE_COLOR = 'PAGE_TOGGLE_COLOR'
export const PAGE_UPDATE_NODE_BODY = 'PAGE_UPDATE_NODE_BODY'
export const PAGE_UPDATE_NODE_IMAGE = 'PAGE_UPDATE_NODE_IMAGE'
export const PAGE_UPDATE_NODE_GALLERY = 'PAGE_UPDATE_NODE_GALLERY'
export const PAGE_SAVE_REQUEST = 'PAGE_SAVE_REQUEST'
export const PAGE_SAVE_SUCCESS = 'PAGE_SAVE_SUCCESS'
export const PAGE_SAVE_FAILED = 'PAGE_SAVE_FAILED'
export const PAGE_CHANGE_WIDTH = 'PAGE_CHANGE_WIDTH'
export const LINKS_INIT = 'LINKS_INIT'
export const LINK_REMOVE = 'LINK_REMOVE'
export const PAGE_MOVE_GALLERY_ITEM = 'PAGE_MOVE_GALLERY_ITEM'
export const PAGE_REMOVE_GALLERY_ITEM = 'PAGE_REMOVE_GALLERY_ITEM'
export const PAGE_SHOW_ATTACHMENTS_CHOOSER = 'PAGE_SHOW_ATTACHMENTS_CHOOSER'
export const PAGE_DID_CHOOSE_ATTACHMENTS = 'PAGE_DID_CHOOSE_ATTACHMENTS'
export const DOWNLOADS_FETCH_REQUEST = 'DOWNLOADS_FETCH_REQUEST'
export const DOWNLOADS_FETCH_SUCCESS = 'DOWNLOADS_FETCH_SUCCESS'
export const DOWNLOADS_FETCH_FAILED = 'DOWNLOADS_FETCH_FAILED'
export const PAGE_SHOW_DOWNLOADS_CHOOSER = 'PAGE_SHOW_DOWNLOADS_CHOOSER'
export const PAGE_DID_CHOOSE_DOWNLOADS = 'PAGE_DID_CHOOSE_DOWNLOADS'
export const PAGE_UPDATE_NODE_DOWNLOAD = 'PAGE_UPDATE_NODE_DOWNLOAD'
export const DOWNLOADS_TOGGLE = 'DOWNLOADS_TOGGLE'
export const DOWNLOADS_COMMIT_SELECTED = 'DOWNLOADS_COMMIT_SELECTED'
export const DOWNLOADS_PAGE_CHANGE = 'DOWNLOADS_PAGE_CHANGE'

export const ItemTypes = {
  NODE: 'node'
}
export const NodeTypes = {
  Text: 'Text',
  Image: 'Image',
  Gallery: 'Gallery',
  Form: 'Form',
  Download: 'Download'
}
