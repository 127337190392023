import {
  ATTACHMENTS_INIT,
  ATTACHMENTS_SEARCH,
  ATTACHMENTS_SEARCH_CHANGED,
  ATTACHMENTS_PAGE_CHANGED,
  ATTACHMENTS_FETCH_REQUEST,
  ATTACHMENTS_FETCH_SUCCESS,
  ATTACHMENTS_FETCH_FAILED,
  ATTACHMENTS_TOGGLE,
  ATTACHMENTS_USAGE_SUCCESS,
  ATTACHMENTS_UPLOAD_REQUEST,
  ATTACHMENTS_UPLOAD_SUCCESS,
  ATTACHMENTS_UPLOAD_PROGRESS,
  ATTACHMENTS_ADD_PREVIEW,
  // ATTACHMENTS_UPLOAD_FAILED,
  ATTACHMENTS_UPLOAD_STARTED,
  // ATTACHMENTS_UPLOAD_CANCELED,
  // ATTACHMENTS_DELETE_REQUEST,
  ATTACHMENTS_UPDATE_REQUEST,
  ATTACHMENTS_DELETE_SUCCESS,
  ATTACHMENTS_UPDATE_SUCCESS,
  ATTACHMENTS_UPDATE_FILE_SUCCESS,
  ATTACHMENTS_SET_MULTI_SELECT,
  ATTACHMENTS_SET_SELECTION
} from './constants'

import attachmentCollectionReducer from './attachmentCollectionReducer'

/*
  Reducer
*/

const initialState = {
  complete: false,
  submitting: false,
  err: null,
  code: '',
  attachments: [],
  usage: {},
  page: 1,
  total: 0,
  perPage: 30,
  q: {},
  selected: [],
  selectedConfirmed: [],
  multiSelect: true,
  multiSelectAppend: false,
  collections: []
}

const newAttachment = (file) => ({
  id: file.tmpId,
  title: file.name,
  titleDe: file.name,
  fileName: file.name,
  fileContentType: 'image/jpeg',
  thumbUrl: file.preview,
})

const setPropArray = (list, prop, value) => (
  list.map((item) => {
    // item[prop] = value
    return { ...item, [prop]: value }
  })
)

const replaceAttachment = (attachments, id, props) => {
  const idx = attachments.findIndex((a) => a.id === id)

  if (idx < 0) {
    return [null, false]
  }

  return [
    [
      ...attachments.slice(0, idx),
      { ...attachments[idx], ...props },
      ...attachments.slice(idx + 1)
    ],
    true
  ]
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case ATTACHMENTS_INIT:
      return {
        ...state,
        attachments: action.attachments,
        total: action.total,
        perPage: action.perPage,
        collections: action.collections
      }
    case ATTACHMENTS_SET_MULTI_SELECT:
      return {
        ...state,
        multiSelect: action.multiSelect,
        multiSelectAppend: action.multiSelectAppend,
      }
    case ATTACHMENTS_UPLOAD_REQUEST:
      return {
        ...state,
        attachments: [newAttachment(action.file)].concat(state.attachments)
      }
    case ATTACHMENTS_UPLOAD_SUCCESS: {
      const [attachments, success] = replaceAttachment(
        state.attachments,
        action.tmpId,
        action.attachment
      )

      if (!success) {
        return state
      }

      const [selected, success1] = replaceAttachment(
        state.selected,
        action.tmpId,
        action.attachment
      )

      return {
        ...state,
        total: state.attachments.total + 1,
        attachments,
        selected: success1 ? selected : state.selected
      }
    }
    case ATTACHMENTS_UPLOAD_STARTED: {
      const [attachments, success] = replaceAttachment(
        state.attachments,
        action.id,
        { cancel: action.cancel }
      )

      if (!success) {
        return state
      }

      return {
        ...state,
        attachments
      }
    }
    case ATTACHMENTS_UPLOAD_PROGRESS: {
      const [attachments, success] = replaceAttachment(
        state.attachments,
        action.id,
        { progress: action.progress }
      )

      if (!success) {
        return state
      }

      const [selected, success1] = replaceAttachment(
        state.selected,
        action.id,
        { progress: action.progress }
      )

      return {
        ...state,
        attachments,
        selected: success1 ? selected : state.selected
      }
    }

    case ATTACHMENTS_SET_SELECTION: {
      const selected = action.attachments
      return {
        ...state,
        selected
      }
    }

    case ATTACHMENTS_TOGGLE:
      const idx = state.selected.findIndex((a) => a.id === action.id)
      let selected = state.selected.slice()

      if (idx >= 0) {
        selected.splice(idx, 1)
      } else {
        if (state.multiSelectAppend) {
          selected.push(state.attachments.find((a) => a.id === action.id))
        } else {
          selected.unshift(state.attachments.find((a) => a.id === action.id))
        }
      }

      if (!state.multiSelect) {
        selected = selected.slice(0, 1)
      }

      return {
        ...state,
        selected
      }
    case ATTACHMENTS_SEARCH_CHANGED:
      return {
        ...state,
        q: action.q,
      }
    case ATTACHMENTS_SEARCH:
      return {
        ...state,
        attachments: setPropArray(state.attachments, 'dirty', true)
      }
    case ATTACHMENTS_PAGE_CHANGED:
      return {
        ...state,
        page: action.page,
        attachments: setPropArray(state.attachments, 'dirty', true)
      }
    case ATTACHMENTS_FETCH_REQUEST:
      return {
        ...state,
        page: action.page,
        q: action.q,
        attachments: setPropArray(state.attachments, 'dirty', true),
        complete: false,
        submitting: true,
      }
    case ATTACHMENTS_FETCH_SUCCESS:
      return {
        ...state,
        // attachments: state.attachments.concat(action.attachments).filter((a) => a.dirty !== true),
        attachments: [...action.attachments],
        complete: true,
        submitting: false,
        total: action.total,
        perPage: action.perPage,
        err: null,
      }
    case ATTACHMENTS_FETCH_FAILED:
      return {
        ...state,
        complete: false,
        submitting: false,
        err: action.err
      }
    case ATTACHMENTS_DELETE_SUCCESS: {
      let attachments = state.attachments.slice()
      const idx = attachments.findIndex((a) => a.id === action.id)
      attachments.splice(idx, 1)

      let selected = state.selected.slice()
      const idx1 = selected.findIndex((a) => a.id === action.id)
      if (idx1 >= 0) {
        selected.splice(idx1, 1)
      }

      return {
        ...state,
        attachments,
        selected
      }
    }

    case ATTACHMENTS_ADD_PREVIEW:
    case ATTACHMENTS_UPDATE_REQUEST: {
      const [attachments, success] = replaceAttachment(
        state.attachments,
        action.attachment.id,
        { ...action.attachment, submitting: true }
      )

      if (!success) {
        return state
      }

      const [selected, success1] = replaceAttachment(
        state.selected,
        action.attachment.id,
        { ...action.attachment, submitting: true }
      )

      return {
        ...state,
        attachments,
        selected: success1 ? selected : state.selected
      }
    }
    case ATTACHMENTS_UPDATE_FILE_SUCCESS:
    case ATTACHMENTS_UPDATE_SUCCESS: {
      const [attachments, success] = replaceAttachment(
        state.attachments,
        action.attachment.id,
        { ...action.attachment, submitting: false }
      )

      if (!success) {
        return state
      }

      const [selected, success1] = replaceAttachment(
        state.selected,
        action.attachment.id,
        { ...action.attachment, submitting: false }
      )

      return {
        ...state,
        attachments,
        selected: success1 ? selected : state.selected
      }
    }
    case ATTACHMENTS_USAGE_SUCCESS:
      const usage = { ...state.usage, [action.id]: action.data }
      return {
        ...state,
        usage
      }

    default:
      return attachmentCollectionReducer(state, action)
  }
}

export default reducer
