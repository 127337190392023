import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { useDrag, useDrop } from 'react-dnd'
import { findDOMNode } from 'react-dom'
import classnames from 'classnames'
import { ItemTypes } from '../constants'


const hover = (item, monitor, { index, moveNode }) => {
  const dragIndex = monitor.getItem().index
  const hoverIndex = index

  // allow move only for existsing nodes
  if (dragIndex === undefined) {
    return
  }

  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return
  }

  // Determine rectangle on screen
  const hoverBoundingRect = findDOMNode(item).getBoundingClientRect()

  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

  // Determine mouse position
  const clientOffset = monitor.getClientOffset()

  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top

  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%

  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return
  }

  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return
  }
  // Time to actually perform the action
  moveNode(dragIndex, hoverIndex)

  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.
  monitor.getItem().index = hoverIndex
}


// @DropTarget(ItemTypes.NODE, nodeTarget, connect => ({
//   connectDropTarget: connect.dropTarget()
// }))
// @DragSource(ItemTypes.NODE, nodeSource, (connect, monitor) => ({
//   connectDragSource: connect.dragSource(),
//   isDragging: monitor.isDragging()
// }))
// export default class Node extends Component {

//   constructor(props) {
//     super(props)
//     this.onClick = this.onClick.bind(this)
//   }

//   onClick () {
//     if (this.onClickNode !== undefined) {
//       this.onClickNode(this.id)
//     }
//   }

const Node = ({
  id,

  children,
  selected,
  onClickNode,
  moveNode,
  index,
  node
}) => {


  const onClick = () => {
    if (onClickNode !== undefined) {
      onClickNode(id)
    }
  }

  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.NODE,
    collect (monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover (item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveNode(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.NODE,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const className = classnames('node', {
    isDragging: isDragging,
    selected: selected,
    half: node && node.width === 'half',
    quarter: node && node.width === 'quarter',
    right: node && node.right,
    clear: node && node.clear,
  })

  return (
    <div className={className} style={{ opacity }} onClick={onClick} ref={ref} data-handler-id={handlerId}>
      <div className='node-content'>
        {children}
      </div>
    </div>
  )
}

Node.propTypes = {
  onClickNode: PropTypes.func,
  moveNode: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.number,
  node: PropTypes.object,
  selected: PropTypes.bool,
  isDragging: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  nodeType: PropTypes.string,
}

export default Node
