import { delay, put, call, select, fork, take, cancel, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  PAGE_ADD_NODE,
  PAGE_UPDATE_NODE,
  PAGE_TOGGLE_COLOR,
  PAGE_UPDATE_NODE_IMAGE,
  PAGE_SAVE_REQUEST,
  PAGE_TOGGLE_NODE,
  PAGE_REQUEST_TOGGLE_NODE,
  PAGE_REMOVE_SELECTED_NODE,
  PAGE_UPDATE_NODE_GALLERY,
  PAGE_SHOW_ATTACHMENTS_CHOOSER,
  PAGE_DID_CHOOSE_ATTACHMENTS,
  DOWNLOADS_FETCH_REQUEST,
  DOWNLOADS_FETCH_SUCCESS,
  DOWNLOADS_FETCH_FAILED,
  PAGE_SHOW_DOWNLOADS_CHOOSER,
  PAGE_DID_CHOOSE_DOWNLOADS,
  DOWNLOADS_PAGE_CHANGE,
  NodeTypes,
  PAGE_SELECT_NODE
} from '../constants'

import {
  deselect,
  updateNode,
  toggleNode,
  saveFailed,
  saveSuccess,
  removeNode,
  updateNodeImage,
  updateNodeGallery,
  loadDownloads,
  updateNodeDownload
} from '../actions'

import {
  load as loadAttachments,
  setMultiSelect as setMultiSelectAttachments,
  setSelection as setAttachmentsSelection
} from '../../Attachments/actions'

import { selectedAttachment, selectAttachmentsSelection } from '../../Attachments/selectors'

import {
  selectPageId,
  selectNodes,
  selectDeletedIds,
  selectSelectedNode,
  selectSelectedId,
  selectNode
} from '../selectors'

import { save as saveNodes, fetchDownloads } from '../api'
import { empty } from '../../utils'

// worker Saga: will be fired on PAGE_UPDATE_NODE_IMAGE actions
function* updateNodeImageWorker (action) {
  // select attachment
  const attachment = yield select(selectedAttachment())
  const node_ = yield select(selectSelectedNode())

  const node = {
    id: action.id,
    attachmentId: attachment.id,
    thumbUrl: attachment.thumbUrl
  }

  if (node_.titleDe === undefined) {
    node.titleDe = attachment.titleDe
    node.titleEn = attachment.titleEn
  }

  yield put(updateNode(node))
}

function* updateNodeGalleryWorker (action) {
  // select attachment
  const attachments = yield select(selectAttachmentsSelection())

  const node = {
    id: action.id,
    attachments
  }

  yield put(updateNode(node))
}

function* addNodeWorker ({ node }) {
  yield put(toggleNode(node.id))
}

function* saveWorker (action) {
  yield put(deselect())

  const pageId = yield select(selectPageId())
  const nodes = yield select(selectNodes())
  const deletedIds = yield select(selectDeletedIds())

  let nodesAttributes = []
  nodes.forEach((node, i) => {
    let snode = {
      nodeType: node.nodeType.toLowerCase(),
      attachmentId: node.attachmentId,
      position: i,
      titleDe: node.titleDe,
      titleEn: node.titleEn,
      subtitleDe: node.subtitleDe,
      subtitleEn: node.subtitleEn,
      bodyDe: node.bodyDe,
      bodyEn: node.bodyEn,
      right: node.right,
      width: node.width,
      fancybox: node.fancybox,
      clear: node.clear,
      darkTitle: node.darkTitle,
      videoUrl: node.videoUrl,
      bgColor: node.bgColor,
      color: node.color,
      overlayTitle: node.overlayTitle,
      anchor: node.anchor
    }

    if (typeof node.link === 'string') {
      const link = node.link.split(':')
      snode.linkableType = link[0]
      snode.linkableId = link[1]
    } else {
      snode.linkableType = null
      snode.linkableId = null
    }

    if (node.nodeType === NodeTypes.Form) {
      snode.formType = node.formType
    }

    if (node.nodeType === NodeTypes.Gallery) {
      snode.attachmentIdsWithPosition = node.attachments.map(a => a.id)
    }

    if (node.id > 0) {
      snode.id = node.id
    }

    if (node.download !== null && node.download !== undefined) {
      snode.downloadId = node.download.id
    }

    nodesAttributes.push(snode)
  })

  nodesAttributes = nodesAttributes.concat(
    deletedIds.map(id => ({
      id,
      _destroy: true
    }))
  )

  try {
    const { data } = yield call(saveNodes, pageId, {
      page: { nodesAttributes }
    })
    yield put(saveSuccess(data.nodes))
  } catch (e) {
    yield put(saveFailed(e))
  }
}

function* removeSelectedNodeWorker () {
  const selectedId = yield select(selectSelectedId())
  yield put(removeNode(selectedId))
}

function* toggleWorker (action) {
  const node = yield select(selectSelectedNode())
  const nextNode = yield select(selectNode(action.id))
  if (node && node.id !== action.id) {
    yield put(deselect())
  }

  // if (node && node.nodeType === NodeTypes.Text && nextNode.nodeType !== NodeTypes.Text) {
  //   yield put(toggleNode(node.id))
  //   // yield delay(1)
  // }

  yield delay(100)
  yield put({
    ...action,
    type: PAGE_TOGGLE_NODE
  })
}

const PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR = 'PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR'

function attachmentChooserWorkerEl (cb_) {
  var cb = cb_
  return function* _attachmentChooserWorkerEl () {
    // wait for chooser to finish
    yield take(PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR)
    //
    const attachment = yield select(selectedAttachment())
    cb(attachment)
  }
}

//
var tinyMCETask

function* attachmentChooserWorker (action) {
  yield put(loadAttachments())
  const node = yield select(selectSelectedNode())
  switch (node.nodeType) {
    case NodeTypes.Image:
      yield put(setMultiSelectAttachments(false))
      break
    case NodeTypes.Text:
      yield put(setMultiSelectAttachments(false))
      // fork tinyMCETask
      tinyMCETask = yield fork(attachmentChooserWorkerEl(action.cb))
      break
    case NodeTypes.Gallery:
      yield put(setMultiSelectAttachments(true, true)) // multi, append
      yield put(setAttachmentsSelection(node.attachments))
      break
  }
  $(`#${action.modalId}`).modal('show')
}

function* didChooseDownloadsWorker (action) {
  const node = yield select(selectSelectedNode())
  switch (node.nodeType) {
    case NodeTypes.Image:
      yield put(updateNodeImage(node.id))
      break
    case NodeTypes.Text:
      // run the _attachmentChooserWorkerEl
      yield put({ type: PAGE_DID_CHOOSE_ATTACHMENTS_REDCATOR })
      // cancel the task
      yield cancel(tinyMCETask)
      break
    case NodeTypes.Gallery:
      yield put(updateNodeGallery(node.id))
      break
  }
  $(`#${action.modalId}`).modal('hide')
}

export function* removeSelectedNodeSaga () {
  yield takeLatest(PAGE_REMOVE_SELECTED_NODE, removeSelectedNodeWorker)
}
/*
  updateNodeImageSaga
  Does not allow concurrent searches of attachments
*/
export function* updateNodeImageSaga () {
  yield takeEvery(PAGE_UPDATE_NODE_IMAGE, updateNodeImageWorker)
}

export function* updateNodeGallerySaga () {
  yield takeEvery(PAGE_UPDATE_NODE_GALLERY, updateNodeGalleryWorker)
}

/*
  updateNodeImageSaga
  Does not allow concurrent searches of attachments
*/
export function* addNodeSaga () {
  yield takeEvery(PAGE_ADD_NODE, addNodeWorker)
}

export function* saveSaga () {
  yield takeLatest(PAGE_SAVE_REQUEST, saveWorker)
}

export function* toggleNodeSaga () {
  yield takeEvery(PAGE_REQUEST_TOGGLE_NODE, toggleWorker)
}

export function* attachmentChooserSaga () {
  yield takeLatest(PAGE_SHOW_ATTACHMENTS_CHOOSER, attachmentChooserWorker)
}

export function* didChooseAttachmentSaga () {
  yield takeLatest(PAGE_DID_CHOOSE_ATTACHMENTS, didChooseDownloadsWorker)
}

function* pageUpdateNodeWorker (action) {
  if (!action.node.useColor) {
    yield put({
      type: PAGE_TOGGLE_COLOR,
      node: {
        ...action.node,
        color: null,
        bgColor: null
      }
    })
  } else if (action.node.useColor && empty(action.node.color)) {
    yield put({
      type: PAGE_TOGGLE_COLOR,
      node: {
        ...action.node,
        color: '#ffffff',
        bgColor: '#cc0000'
      }
    })
  }
}

export function* updateNodeSaga () {
  yield takeEvery(PAGE_UPDATE_NODE, pageUpdateNodeWorker)
}

// Downloads
// worker Saga: will be fired on ATTACHMENTS_FETCH_REQUESTED actions
function* fetchDownloadsWorker (action) {
  try {
    /*
      If typeahead delay to allow the user to finsih typing
    */
    if (action.typeahead) {
      yield delay(350)
    }

    const q = {
      ...action.q
    }

    const { data } = yield call(fetchDownloads, action.page, q)
    yield put({ type: DOWNLOADS_FETCH_SUCCESS, ...data })
  } catch (e) {
    yield put({ type: DOWNLOADS_FETCH_FAILED, message: e.message })
  }
}
export function* fetchDownloadsWatcher () {
  yield takeLatest(DOWNLOADS_FETCH_REQUEST, fetchDownloadsWorker)
}

function* showDownloadChooserWorker (action) {
  yield put(loadDownloads())
  // const node = yield select(selectSelectedNode())
  $(`#${action.modalId}`).modal('show')
}

export function* showDownloadChooserWatcher () {
  yield takeLatest(PAGE_SHOW_DOWNLOADS_CHOOSER, showDownloadChooserWorker)
}

function* didChooseDownloadWorker (action) {
  const node = yield select(selectSelectedNode())
  yield put(updateNodeDownload(node.id))

  $(`#${action.modalId}`).modal('hide')
}

export function* didChooseDownloadWatcher () {
  yield takeLatest(PAGE_DID_CHOOSE_DOWNLOADS, didChooseDownloadWorker)
}

export function* downloadsPageChangeWatcher () {
  yield takeLatest(DOWNLOADS_PAGE_CHANGE, fetchDownloadsWorker)
}

// // select node
// export function* toggleNodeSaga () {
//   yield takeEvery(PAGE_TOGGLE_NODE, toggleNodeWorker)
// }

export default [
  updateNodeImageSaga,
  updateNodeGallerySaga,
  addNodeSaga,
  saveSaga,
  removeSelectedNodeSaga,
  toggleNodeSaga,
  attachmentChooserSaga,
  didChooseAttachmentSaga,
  updateNodeSaga,
  fetchDownloadsWatcher,
  showDownloadChooserWatcher,
  didChooseDownloadWatcher,
  downloadsPageChangeWatcher
]
