import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import DownloadList from '../components/DownloadList'
import { loadDownloadsPage, toggleDownload } from '../actions'

import {
  selectDownloads,
  selectDownloadsSelection,
  selectedDownload,
  selectedDownloadsPagination
} from '../selectors'

const mapStateToProps = createSelector(
  selectDownloads(),
  selectDownloadsSelection(),
  selectedDownload(),
  selectedDownloadsPagination(),
  (downloads, selectedDownloads, selectedDownload, pagination) => {
    return {
      downloads,
      selectedDownloads,
      selectedDownload,
      pagination,
      selectedDownloadId: selectedDownload && selectedDownload.id
    }
  }
)

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClickPage: page => dispatch(loadDownloadsPage(page + 1)),
    toggleDownload: id => dispatch(toggleDownload(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadList)
