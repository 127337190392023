import React, { Component } from 'react'
import PropTypes from "prop-types"
// import update from 'react/lib/update'

import styles from './styles.module.scss'

export default class extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  }
  render () {
    const { children } = this.props
    return (
      <div className={styles.PageBuilder} data-pagebuilder>
        {children}
      </div>
    )
  }
}
