import axios from 'axios'
import Qs from 'qs'
import ReactOnRails from 'react-on-rails'

export const client = (cfg = {}) =>
  axios.create({
    paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
    responseType: 'json',
    headers: ReactOnRails.authenticityHeaders(),
    ...cfg
  })

export const save = (pageId, data) => client().patch(`/admin/pages/${pageId}`, data)

export const fetchDownloads = (page, q) =>
  client().get('/admin/downloads', { params: { page, q: { ...q } } })
