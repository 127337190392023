import React from 'react'
import PropTypes from "prop-types"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AttachmentCollection from './AttachmentCollection'


const AttachmentCollectionApp = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AttachmentCollection {...props} />
    </DndProvider>
  )
}

AttachmentCollectionApp.propTypes = {
  collectionMoveItem: PropTypes.func,
  attachments: PropTypes.arrayOf(PropTypes.object),
  objName: PropTypes.string.isRequired,
  // idParam: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

export default AttachmentCollectionApp
