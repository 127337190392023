import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createSelector } from 'reselect'
import Dropzone from 'react-dropzone'
import { filesize } from 'filesize'
import moment from 'moment'
import { selectedAttachment } from '../../selectors'
import { update, updateFile } from '../../actions'
import AttachmentPreview from '../../components/AttachmentPreview'
import styles from './styles.module.scss'

moment.locale('de')

const renderField = ({ input, label, type, meta: { touched, error } }) => {
  return (
    <div className='form-group'>
      <label>{label}</label>
      <input {...input}
        className='form-control'
        placeholder=''
        type={type || 'text'}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired
}

// const renderRadio = ({ input, valuesAndLabel, handleSubmit, meta: { touched, error } }) => {
//   return (
//     <label className='radio-inline'>
//       <InputGroupWithLabel {...input}
//         type='radio'
//         listOfValuesAndLabels={valuesAndLabel}
//       />
//       {...input}
//     </label>
//   )
// }

// renderRadio.propTypes = {
//   input: PropTypes.object.isRequired,
//   valuesAndLabel: PropTypes.array.isRequired,
//   meta: PropTypes.object.isRequired,
//   handleSubmit: PropTypes.func
// }

const renderUsage = (usage) => {
  if (usage.length === 0) {
    return (
      <h4>Wird nicht verwendet</h4>
    )
  }

  return (
    <div>
      <h4>Wird verwendet</h4>
      <ul className='list-group'>
        {usage.map((u, i) => {
          return (
            <li key={i} className='list-group-item'>
              <a href={u.url}>{u.type} <b>{u.label}</b></a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

let AttachmentForm = ({
  handleSubmit,
  submitUpdate,
  updateFile,
  pristine,
  submitting,
  attachment,
  usage
}) => (
  <div className={styles.AttachmentForm}>
    <form
      className='attachment panel panel-default'
      onSubmit={handleSubmit(submitUpdate)}
    >
      <input type='hidden' name='utf-8' value='✓' />
      <div className='panel-body'>
        <Dropzone
          className='dropzone-single'
          multiple={false}
          onDrop={(files) => updateFile(attachment.id, files[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <div className={styles.previewRow}>
                <AttachmentPreview {...attachment} />
                {attachment.id > 0 &&
                  <div>
                    <br />
                    <a className='btn btn-primary btn-md'>
                      <i className='fa fa-image' />
                      &nbsp;Datein wählen
                    </a>
                    <input {...getInputProps()} />
                    <div>
                      Oder Datei hier hin draggen. &uarr;
                    </div>
                  </div>
                }
              </div>

            </div>
          )}
        </Dropzone>
        {
          attachment.id > 0 &&
          <div>
            <Field name='id' type='hidden' label='' component={renderField} />
            <Field name='titleDe' label='Title de' component={renderField} />
            <Field name='titleEn' label='Title en' component={renderField} />
            <Field name='copyright' label='Copyright' component={renderField} />
            <section className='attchment-folder'>
              <h4>Folder</h4>
              <label><Field name='folderList' component='input' type='radio' value='kulturkreis' /> kulturkreis</label>
              {'  '}
              <label><Field name='folderList' component='input' type='radio' value='arsviva' /> arsviva</label>
              {'  '}
              <label><Field name='folderList' component='input' type='radio' value='jahresring' /> jahresring</label>
            </section>
          </div>
        }
      </div>
      <div className='panel-footer'>
        <button type='submit' className='btn btn-default btn-primary' disabled={pristine || attachment.submitting}>
          Save
        </button>
        {' '}
        {attachment.submitting && <i className='page-builder-saving fa fa-refresh fa-spin' />}
      </div>
    </form >
    {
      attachment.id > 0 &&
      <div className='attachment-props'>
        <div className='prop filename'><b> Dateiname </b>{attachment.fileName}</div>
        <div className='prop filesize'><b> Größe </b>{filesize(attachment.fileSize)}</div>
        <div className='prop dimensions'>
          <b>Dimensionen </b>{attachment.fileWidth} &times; {attachment.fileHeight} px
        </div>
        <div className='prop updated-at'>
          <b>Geändert: </b>{moment(attachment.updatedAt).format('ddd D MMM YYYY HH:mm')} h
        </div>
        <div className='prop created-at'>
          <b>Erstellt: </b>{moment(attachment.createdAt).format('ddd D MMM YYYY HH:mm')} h
        </div>
        <div className='prop url'>
          <b>url: </b>
          <br />
          {attachment.url}
        </div>
      </div>
    }
    {usage && renderUsage(usage)}
  </div >
)

AttachmentForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  attachment: PropTypes.object.isRequired,
  updateFile: PropTypes.func.isRequired,
  usage: PropTypes.arrayOf(PropTypes.object),
}

AttachmentForm = reduxForm({
  form: 'attachment'
})(AttachmentForm)

const mapStateToProps = createSelector(
  selectedAttachment(),
  (attachment, thumbUrl) => ({
    attachment,
    initialValues: attachment,
    enableReinitialize: attachment.id > 0 || parseInt(attachment.progress, 10) === 1
  })
)

const mapDispatchToProps = (dispatch) => {
  return {
    submitUpdate: (attachment) => dispatch(update(attachment)),
    updateFile: (id, file) => {
      dispatch(updateFile(id, file))
    }
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachmentForm)

