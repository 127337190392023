import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Modal from '../../components/Modal'
import { selectDownloadsSelection } from '../selectors'
import DownloadListContainer from './DownloadListContainer'
import Download from '../components/Download'
import { downloadsCommitSelected, toggleDownload } from '../actions'
// import styles from './styles.scss'

class DownloadsChooser extends Component {
  static propTypes = {
    // objId: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired,
    selectedDownloads: PropTypes.arrayOf(PropTypes.object),
    downloadsCommitSelected: PropTypes.func,
    toggleDownload: PropTypes.func
    // didComplete: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.downloadsCommitSelected = this.downloadsCommitSelected.bind(this)
  }

  downloadsCommitSelected () {
    const { selectedDownloads } = this.props
    this.props.downloadsCommitSelected(selectedDownloads[0])
    // this.props.didComplete()
  }

  render () {
    const { selectedDownloads, modalId, toggleDownload } = this.props

    const disabled = selectedDownloads.find(a => a.id < 0) !== undefined

    return (
      <Modal
        divId={modalId}
        title="Download Wählen"
        commit={this.downloadsCommitSelected}
        commitButtonEnbaled={selectedDownloads.length > 0 && !disabled}
      >
        <div className="downloads-chooser row">
          <div className="col-md-9">
            <DownloadListContainer editable={false} autoSelectAfterUpload />
          </div>
          <div className="col-md-3">
            {selectedDownloads.map(a => (
              <div className="downloads-selected" key={a.id}>
                <Download {...a} toggleDownload={toggleDownload} />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = createSelector(selectDownloadsSelection(), selectedDownloads => ({
  selectedDownloads
}))

const mapDispatchToProps = dispatch => {
  return {
    toggleDownload: id => dispatch(toggleDownload(id)),
    downloadsCommitSelected: download => dispatch(downloadsCommitSelected(download))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsChooser)
