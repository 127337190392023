import {
  combineReducers,
  applyMiddleware,
  compose
} from 'redux'
import { configureStore } from '@reduxjs/toolkit'
// import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'

import attachmentsReducer from './reducer'
import { initAttachments } from './actions'

import sagas from './sagas'

const logger = createLogger({
  level: 'info',
  collapsed: true,
})

const rootReducer = combineReducers({
  attachments: attachmentsReducer,
  form: formReducer
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const enhancer = compose(
  false && process.env.NODE_ENV === 'development'
    ? applyMiddleware(logger, sagaMiddleware)
    : applyMiddleware(sagaMiddleware),
  window.devToolsExtension ? window.devToolsExtension() : noop => noop
)

export default function initStore (initData, railsContext) {
  const {
    attachments,
    total,
    perPage,
    collections
  } = initData
  const state = rootReducer(undefined, initAttachments(attachments, total, perPage, collections))
  const store = configureStore({ reducer: rootReducer, preloadedState: state, enhancers: [enhancer] })

  // run all default exported sagas
  sagas.map(sagaMiddleware.run)

  if (module.hot) {
    module.hot.accept('./reducer', () =>
      store.replaceReducer(require('./reducer')) // eslint-disable-line global-require
    )
  }
  return store
}
