import React from 'react'
import { Provider } from 'react-redux'
import ReactOnRails from 'react-on-rails'
import Attachments from './Attachments'
import AttachmentChooserButton from './Attachments/containers/AttachmentChooserButton'
import AttachmentCollectionLauncher from './Attachments/containers/AttachmentCollectionLauncher'
import TokenInputApp from './TokenInputApp'
import PageBuilderApp from './PageBuilder'

export const AttachmentsApp = (props) => {
  const store = ReactOnRails.getStore('attachmentsStore')
  return (
    <Provider store={store}>
      <Attachments {...props} />
    </Provider>
  )
}

export const AttachmentChooser = (props) => {
  const store = ReactOnRails.getStore('attachmentsStore')
  return (
    <Provider store={store}>
      <AttachmentChooserButton {...props} />
    </Provider>
  )
}

export const AttachmentCollectionChooser = (props) => {
  const store = ReactOnRails.getStore('attachmentsStore')
  return (
    <Provider store={store}>
      <AttachmentCollectionLauncher {...props} />
    </Provider>
  )
}

export const TokenInput = (props) => (
  <TokenInputApp {...props} />
)

export const PageBuilder = (props) => {
  const store = ReactOnRails.getStore('pageStore')
  return (
    <Provider store={store}>
      <PageBuilderApp {...props} />
    </Provider>
  )
}
