import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import AttachmentList from '../../components/AttachmentList'
import { addFiles, loadPage, destroy, toggle, toggleAndLoadUsage } from '../../actions'

import { selectAttachments, selectAttachmentsSelection, selectedAttachment } from '../../selectors'

const mapStateToProps = createSelector(
  selectAttachments(),
  selectAttachmentsSelection(),
  selectedAttachment(),
  ({ attachments, page, total, perPage }, selection, selectedAttachment) => ({
    attachments,
    page,
    total,
    perPage,
    selectedIds: selection.map(a => a.id),
    selectedAttachment
  })
)

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAddFiles: (file, autoSelect) => dispatch(addFiles(file, autoSelect)),
    onClickPage: page => dispatch(loadPage(page + 1)),
    onClickDeleteItem: id => dispatch(destroy(id)),
    onClickItem: id => dispatch(ownProps.loadUsage ? toggleAndLoadUsage(id) : toggle(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentList)
