import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Modal from '../../../components/Modal'
import { selectAttachmentsSelection } from '../../selectors'
import AttachmentListContainer from '../AttachmentListContainer'
import AttachmentPreview from '../../components/AttachmentPreview'
import { setCollection, toggle } from '../../actions'
import styles from './styles.module.scss'

class AttachmentsChooser extends Component {
  // eslint-disable-line react/prefer-stateless-function
  static propTypes = {
    objId: PropTypes.string.isRequired,
    modalId: PropTypes.string.isRequired,
    selectAttachments: PropTypes.arrayOf(PropTypes.object),
    setCollection: PropTypes.func,
    toggle: PropTypes.func,
    didComplete: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.setCollection = () => this._setCollection()
  }

  _setCollection () {
    const { objId, selectAttachments } = this.props
    this.props.setCollection(objId, selectAttachments)
    this.props.didComplete()
  }

  render () {
    const { selectAttachments, toggle, modalId } = this.props

    const disabled = selectAttachments.find(a => a.id < 0) !== undefined
    const inModal = true
    return (
      <Modal
        divId={modalId}
        title="Dateien Wählen"
        commit={this.setCollection}
        commitButtonEnbaled={selectAttachments.length > 0 && !disabled}
      >
        <div className={`${styles.AttachmentsChooser} row`}>
          <div className="col-md-9">
            <AttachmentListContainer editable={false} inModal autoSelectAfterUpload />
          </div>
          <div className="col-md-3">
            {selectAttachments.map(a => (
              <div className="attachmentSelected" key={a.id} onClick={() => toggle(a.id)}>
                <AttachmentPreview {...a} />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = createSelector(selectAttachmentsSelection(), selectAttachments => ({
  selectAttachments
}))

const mapDispatchToProps = dispatch => {
  return {
    toggle: id => dispatch(toggle(id)),
    setCollection: (id, attachments) => dispatch(setCollection(id, attachments))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsChooser)
