/**
 *
 * AttachmentList
 *
 */
import React, { useCallback, useRef } from 'react'
import PropTypes from "prop-types"
import Pager from 'react-pager'
import { useDropzone } from 'react-dropzone'
import Attachment from '../Attachment'
import styles from './AttachmentList.module.scss'
import AttachmentsFilterForm from '../../containers/AttachmentsFilterForm'



const AttachmentList = (props) => {
  const visiblePages = 10
  const {
    attachments,
    page,
    total,
    perPage,
    onClickItem,
    onClickDeleteItem,
    onAddFiles,
    onClickPage,
    selectedIds,
    selectedAttachment,
    editable,
    autoSelectAfterUpload,
    inModal
  } = props

  // const pagerRef = useRef(null)
  // const itemsRef = useRef(null)

  const totalPages = Math.ceil(total / perPage)
  let pager = null
  if (totalPages > 1) {

    pager = (
      <div>
        <Pager
          total={totalPages}
          current={page - 1}
          visiblePages={visiblePages}
          titles={{ first: '|<', last: '>|' }}
          onPageChanged={onClickPage}
        />
      </div>
    )
  }
  const onDrop = useCallback(files => {
    onAddFiles(files, autoSelectAfterUpload)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  return (
    <div className={styles.attachmentList}>
      <div className="attachments-toolbar">

        <div {...getRootProps()} >
          <input {...getInputProps()} />
          <div className="drop-area">
            <a className="btn btn-primary">
              <i className="fa fa-plus" /> Dateien hinzufügen
            </a>
            <div>
              Dateien hier hin draggen.
            </div>
          </div>
        </div>

        <div className="form-inline attachments-toolbar-search">
          <AttachmentsFilterForm />
        </div>
      </div>
      <div className="items">
        {attachments.map(a => (
          <Attachment
            key={a.id}
            editable={editable}
            inSelection={selectedIds.includes(a.id)}
            isSelected={selectedAttachment && selectedAttachment.id === a.id}
            onClick={() => onClickItem(a.id)}
            onClickDelete={() => onClickDeleteItem(a.id)}
            {...a}
          />
        ))}
      </div>
      <div className={`${styles.footer} footer ${inModal ? styles.inModal : ''} ${inModal ? 'in-modal' : ''}`}>
        {pager}
      </div>
    </div>
  )
}


export default AttachmentList



