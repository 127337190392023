
import { put, select, call, takeEvery } from 'redux-saga/effects'
import {
  ATTACHMENTS_DELETE_REQUEST,
  ATTACHMENTS_DELETE_SUCCESS,
  ATTACHMENTS_DELETE_FAILED
} from '../constants'
import { cancelUpload } from '../actions'
import { destroy } from '../api'
import { selectAttachment } from '../selectors'

function* deleteAttachment (action) {
  // Select attachment from store
  const attachment = yield select(selectAttachment(action.id))

  if (attachment.id < 0) {
    // new cancel upload
    yield put(cancelUpload(action.id))
    // remove from store
    yield put({ type: ATTACHMENTS_DELETE_SUCCESS, id: action.id })
  } else {
    try {
      // delete on server
      yield call(destroy, action.id)
      yield put({ type: ATTACHMENTS_DELETE_SUCCESS, id: action.id })
    } catch (e) {
      yield put({ type: ATTACHMENTS_DELETE_FAILED, message: e.message })
    }
  }
}

// delete
export default function* deleteSaga () {
  yield takeEvery(ATTACHMENTS_DELETE_REQUEST, deleteAttachment)
}
