import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { selectCollection } from '../../selectors'
import AttachmentPreview from '../../components/AttachmentPreview'
import AttachmentChooser from '../AttachmentChooser'
import {
  load,
  setMultiSelect,
} from '../../actions'

const modalId = 'acb-modal'

class AttachmentChooserButton extends Component {
  static propTypes = {
    objName: PropTypes.string.isRequired,
    objId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loadAttachments: PropTypes.func.isRequired,
    didComplete: PropTypes.func.isRequired,
    selectedAttachment: PropTypes.object,
    setSingleSelect: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.onClick = (e) => this._onClick(e)
  }

  componentDidMount () {
    this.props.setSingleSelect()
  }

  _onClick (e) {
    e.preventDefault()
    setTimeout(() => {
      this.props.loadAttachments()
      this.props.onClick(this.props.selectedAttachment)
    }, 1)
  }

  render () {
    const {
      objName,
      selectedAttachment,
      objId,
      didComplete
    } = this.props

    const attachmentId = (selectedAttachment && selectedAttachment.id) || ''

    return (
      <div>
        <a onClick={this.onClick} className='btn btn-default'>
          {selectedAttachment && selectedAttachment.id && <AttachmentPreview {...selectedAttachment} />}
          Datei wählen
          <input
            type='hidden'
            name={`${objName}[attachment_id]`}
            value={attachmentId} />
        </a>
        <AttachmentChooser objId={objId} modalId={modalId} didComplete={didComplete} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const collection = selectCollection(ownProps.objId)(state)
  return {
    selectedAttachment: collection && collection[0]
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadAttachments: () => dispatch(load()),
    setSingleSelect: (a) => {
      dispatch(setMultiSelect(false))
    },
    onClick: (e) => {
      $(`#${modalId}`).modal('show')
    },
    didComplete: () => $(`#${modalId}`).modal('hide')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentChooserButton)
