import React from 'react'
import PropTypes from 'prop-types'
import styles from './download.module.scss'

const Download = ({ id, thumbUrl, titleDe, fileName, fileSize, toggleDownload, selected }) => (
  <div className={`${styles.Download} download ${selected}`} onClick={() => toggleDownload && toggleDownload(id)}>
    <img src={thumbUrl} width={100} height={100} />
    <div className="download-props">
      <div className="download-title">{titleDe}</div>
      <div className="download-filename">{fileName}</div>
      <div className="download-filesize">{fileSize}</div>
    </div>
  </div>
)

Download.propTypes = {
  thumbUrl: PropTypes.string,
  id: PropTypes.number.isRequired,
  selected: PropTypes.string,
  titleDe: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  toggleDownload: PropTypes.func
}

export default Download
