import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, DndProvider } from "react-dnd";
import PageBuilder from "../components/PageBuilder";
import { NodeTypes } from "../constants";
import PageContainer from "./PageContainer";
import NodeForm from "./NodeForm";
import ErrorBoundary from "../../components/ErrorBoundary";
import { createSelector } from "reselect";
import {
  selectSelectedNode,
  selectWidth,
  selectSubmitting,
  selectErr
} from "../selectors";
import { save, addNode, changeWidth, removeSelectedNode } from "../actions";

// @DragDropContext(HTML5Backend)
class App extends Component {
  static propTypes = {
    save: PropTypes.func.isRequired,
    selectedNode: PropTypes.object,
    addImage: PropTypes.func.isRequired,
    addGallery: PropTypes.func.isRequired,
    addText: PropTypes.func.isRequired,
    addForm: PropTypes.func.isRequired,
    addDownload: PropTypes.func.isRequired,
    removeSelectedNode: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    incWidth: PropTypes.func.isRequired,
    decWidth: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    err: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.keyHandler = this.keyHandler.bind(this);
  }

  componentDidMount () {
    // wee need to set the height after we get pageWrap ref
    setTimeout(() => this.forceUpdate(), 1);

    document.addEventListener("keydown", this.keyHandler);
  }

  componentWillUnmount () {
   document.removeEventListener("keydown", this.keyHandler);
  }

  keyHandler (e) {
    switch (e.keyCode) {
      case 46:
        // case 8:
        this.props.removeSelectedNode();
        break;
      case 187:
        this.props.incWidth();
        break;
      case 189:
        this.props.decWidth();
        break;
    }
    // console.log('key code', e.keyCode)
  }

  render () {
    const {
      selectedNode,
      width,
      save,
      addImage,
      addGallery,
      addText,
      addForm,
      addDownload,
      incWidth,
      decWidth,
      submitting,
      err
    } = this.props;

    let height = '100%'

    try {
      height =  $(window).height() -
      (this.refs.pageWrap ? $(this.refs.pageWrap).offset().top : 220);
    }catch(e){ debugger}

    const clsn = classnames("page-builder-items", `width-${width}`);

    return (
      <DndProvider backend={HTML5Backend}>

        <PageBuilder>
          <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <div className="page-builder-toolbar node-add-buttons">
              <div className="btn-group">
                <a className="btn btn-default" onClick={addImage}>
                  <i className="fa fa-image" />
                  &nbsp; Bild hinzufügen
                </a>
                <a className="btn btn-default" onClick={addGallery}>
                  <i className="fa fa-th-large" />
                  &nbsp; Gallery hinzufügen
                </a>
                <a className="btn btn-default" onClick={addText}>
                  <i className="fa fa-font" />
                  &nbsp; Text hinzufügen
                </a>
                <a className="btn btn-default" onClick={addForm}>
                  <i className="fa fa-cog" />
                  &nbsp; Formular hinzufügen
                </a>
                <a className="btn btn-default" onClick={addDownload}>
                  <i className="fa fa-download" />
                  &nbsp; Download hinzufügen
                </a>
              </div>
              <div className="btn-group">
                <a
                  className="btn btn-default"
                  onClick={decWidth}
                  disabled={width === 20}
                >
                  <i className="fa fa-search-minus" />
                </a>
                <a
                  className="btn btn-default"
                  onClick={incWidth}
                  disabled={width === 100}
                >
                  <i className="fa fa-search-plus" />
                </a>
              </div>

              <a className="btn btn-success" onClick={save} disabled={submitting}>
                Speichern
              </a>
              {submitting && (
                <i className="page-builder-saving fa fa-refresh fa-spin" />
              )}
              {err && err.message}
            </div>

            <div className="page-builder-editor" ref="pageWrap">
              <div className={clsn} style={{ width: `${width}%`, height: height }}>
                <PageContainer />
              </div>
              <div className="page-builder-sidebar">
                {selectedNode && <NodeForm />}
              </div>
            </div>
          </ErrorBoundary>
        </PageBuilder></DndProvider >
    );
  }
}

const mapStateToProps = createSelector(
  selectSelectedNode(),
  selectWidth(),
  selectSubmitting(),
  selectErr(),
  (selectedNode, width, submitting, err) => ({
    selectedNode,
    width,
    submitting,
    err
  })
);

const mapDispatchToProps = dispatch => ({
  save: () => dispatch(save()),
  addImage: () => dispatch(addNode(NodeTypes.Image)),
  addGallery: () => dispatch(addNode(NodeTypes.Gallery)),
  addText: () => dispatch(addNode(NodeTypes.Text)),
  addForm: () => dispatch(addNode(NodeTypes.Form)),
  addDownload: () => dispatch(addNode(NodeTypes.Download)),
  incWidth: () => dispatch(changeWidth(10)),
  decWidth: () => dispatch(changeWidth(-10)),
  removeSelectedNode: () => dispatch(removeSelectedNode())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
