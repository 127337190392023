import React from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import Card from '../../Attachments/components/AttachmentCollection/Card'
import AttachmentPreview from '../../Attachments/components/AttachmentPreview'
import {
  moveGalleryItem,
  removeGalleryItem
} from '../actions'

function galleryBilder (nodeId) {
  const e = document.querySelector(".page-builder-container")
  window.launchGalleryBilder(e, nodeId)
}




const GalleryForm = ({
  node,
  moveItem,
  removeItem,
  onClickGallery
}) => (
  node.id > 0 ? (
    <div className="form-group">
      <button type="button" className="btn btn-default btn-select-gallery-images" onClick={() => galleryBilder(node.id)}>
        <i className="fa fa-image" />
        {' '}
        Bearbeiten
      </button>
    </div>) : <div className="note">Page muss gespeichert werden um die Gallery zu Bearbeiten.</div>
)

GalleryForm.propTypes = {
  onClickGallery: PropTypes.func.isRequired,
  node: PropTypes.object.isRequired,
  moveItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  moveItem: (nodeId, b, c) => dispatch(moveGalleryItem(nodeId, b, c)),
  removeItem: (nid, aid) => dispatch(removeGalleryItem(nid, aid)),
})

export default connect(null, mapDispatchToProps)(GalleryForm)
