import { LINKS_INIT } from './constants'

const initialState = []

function reducer (state = initialState, action) {
  switch (action.type) {
    case LINKS_INIT:
      return action.links.slice()
    default:
      return state
  }
}

export default reducer
