import { eventChannel, END } from 'redux-saga'
import { put, select, call, fork, take, join, takeLatest } from 'redux-saga/effects'
import {
  ATTACHMENTS_UPDATE_REQUEST,
  ATTACHMENTS_UPDATE_SUCCESS,
  ATTACHMENTS_UPDATE_FAILED,
  ATTACHMENTS_UPDATE_FILE_REQUEST,
  ATTACHMENTS_UPDATE_FILE_SUCCESS,
  ATTACHMENTS_UPDATE_FILE_FAILED
} from '../constants'
import {
  update,
  updateFile,
  cancelSource
} from '../api'
import { uploadStarted, uploadProgress } from '../actions'
import { selectAttachment } from '../selectors'

let cancelations = new Map()

// worker Saga: will be fired on ATTACHMENTS_UPDATE_REQUESTED actions
function* updateAttachments (action) {
  try {
    const { id, titleDe, titleEn, copyright, file, folderList } = action.attachment
    const { data } = yield call(update, id, { attachment: { titleDe, titleEn, copyright, file, folderList } })
    yield put({ type: ATTACHMENTS_UPDATE_SUCCESS, ...data })
  } catch (e) {
    yield put({ type: ATTACHMENTS_UPDATE_FAILED, message: e.message })
  }
}

/******************
  Upload
 ******************/

const progressChannel = (id, cancel) => {
  let _emitter
  const chan = eventChannel(emitter => {
    _emitter = emitter
    return () => cancel()
  })

  return {
    chan,
    cb: (progress) => {
      if (progress < 1) {
        _emitter({ id, progress })
      } else {
        _emitter(END)
      }
    }
  }
}

function* progressRepporter (id, chan) {
  // progress
  while (true) {
    // get progress
    let { id, progress } = yield take(chan)
    // put progress
    yield put(uploadProgress(id, progress))
  }
}



function* _uploadAttachment (id, file) {
  let task
  try {
    // axios cancel source
    const source = cancelSource()

    cancelations[id] = source.cancel

    // fire  uploadStarted action add cancel func
    yield put(uploadStarted(id))

    // progress event channel
    const { chan, cb } = yield call(progressChannel, id, source.cancel)

    // fork api upload so we can continue execution
    task = yield fork(updateFile, id, file, source.token, cb)

    // fork progressRepporter, continue wait for task to finish
    yield fork(progressRepporter, id, chan)

    // wait for upload task to process response
    const resp = yield join(task)
    // fire sucess
    yield put({
      type: ATTACHMENTS_UPDATE_FILE_SUCCESS,
      attachment: { ...resp.data.attachment, cancel: null, progress: 1 }
    })
  } catch (e) {
    // put failed
    yield put({ type: ATTACHMENTS_UPDATE_FILE_FAILED, message: e.message })
  }
}

function* _updateFile (action) {
  const { id, file } = action
  const attachment = yield select(selectAttachment(id))
  // fire thumb change
  yield put({ type: ATTACHMENTS_UPDATE_SUCCESS, attachment: { ...attachment, thumbUrl: file.preview } })
  // upload
  yield fork(_uploadAttachment, id, file)
}

/*
  updateSaga
  Does not allow concurrent update of attachments
*/
export function* updateSaga () {
  yield takeLatest(ATTACHMENTS_UPDATE_REQUEST, updateAttachments)
}

export function* updateFileSaga () {
  yield takeLatest(ATTACHMENTS_UPDATE_FILE_REQUEST, _updateFile)
}

export default [
  updateSaga,
  updateFileSaga
]
