import React, { Component } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { createSelector } from 'reselect'

import AttachmentChooser from '../../Attachments/containers/AttachmentChooser'
import DownloadChooser from './DownloadChooser'
import ImageForm from './ImageForm'
import GalleryForm from './GalleryForm'
import DownloadForm from './DownloadForm'

import FSelect from '../components/FSelect'

import {
  updateNode,
  updateNodeBody,
  removeSelectedNode,
  showAttachmentChooser,
  showDownloadsChooser,
  didChooseAttachments,
  removeLink
} from '../actions'

import { selectSelectedNode, selectLinks } from '../selectors'

import { NodeTypes } from '../constants'

import './styles.scss'

const modalId = 'NodeForm-modal'
const modalDownloadId = 'NodeForm-download-modal'

const objId = node => `node${node.id}`
// $(lnk).attr('href'))
const getTinyMCECss = () => Array.from(document.querySelectorAll('[rel=tinymce]')).map(lnk => lnk.getAttribute('href'))

const initTinyMCE = (sel, cb, imgCb) =>
  tinyMCE.init({
    target: sel,
    toolbar: ['undo redo | styles |  bold italic | imgbrowserbtn media link table hr |',
      ' alignleft aligncenter alignright | bullist numlist  | code | removeformat'],
    plugins: 'image link table lists media code',
    menubar: false,
    statusbar: true,
    branding: false,
    convert_urls: false,
    paste_as_text: true,
    plugin_preview_height: 800,
    plugin_preview_width: 1000,
    image_advtab: true,
    style_formats: [
      { title: 'Headline 1', block: 'h1' },
      { title: 'Headline 2', block: 'h2' },
      { title: 'Headline 3', block: 'h3' },
      { title: 'XXL Text', inline: 'span', classes: 'text-big' },
      { title: 'Akzent', inline: 'span', classes: 'accent' },
      { title: 'Text Large', inline: 'span', classes: 'text-lg' },
      { title: 'Text', inline: 'span', classes: 'text-n' },
    ],
    content_css: getTinyMCECss(),
    setup: editor => {
      // debugger
      editor.on('change', e => {
        cb(editor.getContent())
      });
      editor.ui.registry.addButton('imgbrowserbtn', {
        text: '',
        icon: 'image',
        onAction: (_) => {
          imgCb(attachment => {
            const srcset = [
              ['w230', '230w'],
              ['w320', '320w'],
              ['w400', '400w'],
              ['w460', '460w'],
              ['w640', '640w'],
              ['large', '940w']
            ]
              .map(
                f =>
                  `${attachment.url.replace(/\/([^\/]+)\/?$/, m => `/${f[0]}_${m.substr(1)}`)} ${f[1]
                  }`
              )
              .join(', ')

            editor.insertContent(
              `<img src="${attachment.url}" ` +
              `srcset="${srcset}" sizes="(min-width: 700px) 67vw, 100vw" />`
            )
          })
        }
      })
    }

  })

const renderCheckbox = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className="form-group">
      <div className="checkbox">
        <label>
          <input
            {...input}
            label=""
            placeholder={label}
            type={type || 'text'}
            onChange={data => {
              input.onChange(data)
              setTimeout(handleSubmit, 1)
            }}
          />{' '}
          {label}
        </label>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}

renderCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderField = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <input
        className="form-control"
        {...input}
        type={type || 'text'}
        onChange={data => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {touched && error && <span>{error}</span>}
    </div>
  )
}

renderField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}

const renderRadio = ({ input, label, type, handleSubmit, meta: { touched, error } }) => {
  return (
    <label className="radio-inline">
      <input
        {...input}
        // placeholder={label}
        type={type || 'text'}
        onChange={data => {
          input.onChange(data)
          setTimeout(handleSubmit, 1)
        }}
      />
      {label}
    </label>
  )
}

renderRadio.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  meta: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func
}



class NodeForm extends Component {
  static propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitUpdate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showAttachmentChooser: PropTypes.func.isRequired,
    showDownloadsChooser: PropTypes.func.isRequired,
    node: PropTypes.object.isRequired,
    didChooseAttachments: PropTypes.func.isRequired,
    // didChooseDownloads: PropTypes.func.isRequired,
    links: PropTypes.array.isRequired,
    updateNodeBody: PropTypes.func.isRequired,
    removeSelectedNode: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.setBodyDe = this.setBodyDe.bind(this)
    this.setBodyEn = this.setBodyEn.bind(this)
    this.showAttachmentChooser = this.showAttachmentChooser.bind(this)
  }

  componentDidMount () {

    this.initTinyMCE()
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.node.nodeType === NodeTypes.Text) {
  //     if (nextProps.node.id !== this.props.node.id) {
  //       if (tinyMCE.get('bodyDe') !== null && tinyMCE.get('bodyDe').getContent() !== nextProps.node.bodyDe) {
  //         tinyMCE.get('bodyDe').setContent(nextProps.node.bodyDe || '')
  //       }
  //       if (tinyMCE.get('bodyEn') !== null && tinyMCE.get('bodyEn').getContent() !== nextProps.node.bodyEn) {
  //         tinyMCE.get('bodyEn').setContent(nextProps.node.bodyEn || '')
  //       }
  //     } else {
  //       if (this.props.node.nodeType !== NodeTypes.Text) {
  //         setTimeout(() => this.initTinyMCE())
  //       }
  //     }
  //   } else {
  //     this.removeTinyMCE()
  //   }
  // }

  componentWillReceiveProps (nextProps) {

    if (nextProps.node.id !== this.props.node.id) {
      this.removeTinyMCE()
    }
    const hasText = node => node.nodeType === NodeTypes.Text || node.nodeType === NodeTypes.Image

    if (hasText(nextProps.node)) {
      if (nextProps.node.id !== this.props.node.id) {
        setTimeout(() => {
          this.initTinyMCE()
          if (
            tinyMCE.get('bodyDe') !== null
            /* &&
            tinyMCE.get('bodyDe').getContent() !== nextProps.node.bodyDe
            */
          ) {
            tinyMCE.get('bodyDe').setContent(nextProps.node.bodyDe || '')
          }
          if (
            tinyMCE.get('bodyEn') !== null &&
            tinyMCE.get('bodyEn').getContent() !== nextProps.node.bodyEn
          ) {
            tinyMCE.get('bodyEn').setContent(nextProps.node.bodyEn || '')
          }
        }, 1)
      }
    } else {
      this.removeTinyMCE()
    }
  }

  showAttachmentChooser (el) {
    this.props.showAttachmentChooser(el)
  }

  removeTinyMCE () {
    // debugger
    const edDe = tinyMCE.get('bodyDe')
    if (edDe !== null) {
      edDe.remove()
    }

    const edEn = tinyMCE.get('bodyEn')
    if (edEn !== null) {
      edEn.remove()
    }
  }

  initTinyMCE () {
    console.log('==> initTinyMCE')

    this.removeTinyMCE()

    // de
    const $redactorDe = document.getElementById('bodyDe')
    if (!$redactorDe) return
    // $redactorDe.dataset['tinymce-init'] =  true
    try {
      initTinyMCE($redactorDe, this.setBodyDe, this.showAttachmentChooser)
    } catch (e) {
      debugger
    }

    // en
    const $redactorEn = document.getElementById('bodyEn')
    // $redactorEndataset['tinymce-init'] =  true
    try {
      initTinyMCE($redactorEn, this.setBodyEn, this.showAttachmentChooser)
    } catch (e) {
      debugger
    }
  }

  setBodyDe () {
    const { node, updateNodeBody } = this.props
    const html = tinyMCE.get('bodyDe').getContent()
    updateNodeBody(node.id, html, false)
  }

  setBodyEn () {
    const { node, updateNodeBody } = this.props
    const html = tinyMCE.get('bodyEn').getContent()
    updateNodeBody(node.id, html, true)
  }

  renderImageFields () {
    const { handleSubmit, submitUpdate, showAttachmentChooser, node, links, removeLink } = this.props

    const currentLink = links.find((lnk) => lnk.value == node.link)
    const delLink = (e) => {
      e.preventDefault()
      setTimeout(() => removeLink(node.id), 100)
    }

    return (
      <fieldset>
        <ImageForm node={node} onClickImage={showAttachmentChooser} />
        <Field
          name="fancybox"
          type="checkbox"
          label="Bei Klick als Vollbild anzeigen?"
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        {!node.fancybox && (
          <fieldset>
            <div className="form-group">
              <label>Link</label>
              {currentLink && (<div className="current-link">
                <div>{currentLink.label}</div>
                <div className="btn btn-default" onClick={delLink}>&times;</div>
              </div>)}
              <Field
                name="link"
                label="Link"
                options={links}
                linkValue={node.link}
                component={FSelect}
                handleSubmit={handleSubmit(submitUpdate)}
              />

            </div>
            <Field
              name="anchor"
              label="Anchor"
              component={renderField}
              handleSubmit={handleSubmit(submitUpdate)}
            />
          </fieldset>
        )}
        <Field
          id="titleDe"
          name="titleDe"
          type="text"
          label="Titel de"
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="subtitleDe"
          type="text"
          label="Subtitel de"
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="titleEn"
          id="titleEn"
          type="text"
          label="Titel en"
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="subtitleEn"
          type="text"
          label="Subtitel en"
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="darkTitle"
          type="checkbox"
          label="Title Dunkel"
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="overlayTitle"
          type="checkbox"
          label="Title auf dem Bild?"
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="videoUrl"
          type="text"
          label="Vimeo oder YouTube URL (zB. https://vimeo.com/151890784)"
          component={renderField}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <div className="form-group">
          <label>Deutsch</label>
          <input ref="bodyDe" id="bodyDe" style={{ opacity: 0 }} value={node.bodyDe || ''} onChange={() => { }} />
          <b>WICHTIG:</b> Nicht direkt von Programmen wie MS-Word oder Apple-Pages Copy & Paste
          machen.
        </div>
        <div className="form-group">
          <label>English</label>
          <input ref="bodyEn" id="bodyEn" style={{ opacity: 0 }} value={node.bodyEn || ''} onChange={() => { }} />
        </div>
      </fieldset>
    )
  }

  renderGalleryFields () {
    const { node, showAttachmentChooser } = this.props
    return (
      <div>
        <GalleryForm node={node} onClickGallery={showAttachmentChooser} />
      </div>
    )
  }

  renderDownloadFields () {
    const { node, showDownloadsChooser } = this.props
    return (
      <div>
        <DownloadForm node={node} onClickDownload={showDownloadsChooser} />
      </div>
    )
  }

  renderTextFields () {
    const { node, handleSubmit, submitUpdate } = this.props
    return (
      <fieldset>
        <div className="form-group">
          <label>Deutsch</label>
          <input ref="bodyDe" id="bodyDe" style={{ opacity: 0 }} value={node.bodyDe || ''} onChange={() => { }} />
          <b>WICHTIG:</b> Nicht direkt von Programmen wie MS-Word oder Apple-Pages Copy & Paste
          machen.
        </div>
        <div className="form-group">
          <label>English</label>
          <input ref="bodyEn" id="bodyEn" style={{ opacity: 0 }} value={node.bodyEn || ''} onChange={() => { }} />
        </div>
        <Field
          name="useColor"
          type="checkbox"
          label="Farben"
          checked={node.useColor || node.color !== undefined}
          component={renderCheckbox}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        {node.useColor && (
          <div className="row">
            <div className="col-md-6">
              <Field
                name="color"
                type="color"
                label="Color"
                component={renderField}
                handleSubmit={handleSubmit(submitUpdate)}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="bgColor"
                type="color"
                label="Background Color"
                component={renderField}
                handleSubmit={handleSubmit(submitUpdate)}
              />
            </div>
          </div>
        )}
      </fieldset>
    )
  }

  renderFormFields () {
    const { handleSubmit, submitUpdate } = this.props

    return (
      <fieldset>
        <legend>Formular</legend>
        <Field
          name="formType"
          type="radio"
          value="contact"
          label="Kontakt"
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="formType"
          type="radio"
          value="newsletter"
          label="Newsletter"
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)}
        />
        <Field
          name="formType"
          type="radio"
          value="login"
          label="Login"
          component={renderRadio}
          handleSubmit={handleSubmit(submitUpdate)}
        />
      </fieldset>
    )
  }

  renderFields () {
    const { node } = this.props

    switch (node.nodeType) {
      case NodeTypes.Image:
        return this.renderImageFields()
      case NodeTypes.Gallery:
        return this.renderGalleryFields()
      case NodeTypes.Text:
        return this.renderTextFields()
      case NodeTypes.Form:
        return this.renderFormFields()
      case NodeTypes.Download:
        return this.renderDownloadFields()
    }
  }

  render () {
    const {
      handleSubmit,
      submitUpdate,
      node,
      didChooseAttachments,
      // didChooseDownloads,
      removeSelectedNode
    } = this.props
    return (
      <div>
        {node.id > 0 && (
          <div>
            Anchor: <code>#n-{node.id}</code>
          </div>
        )}
        <AttachmentChooser
          modalId={modalId}
          objId={objId(node)}
          didComplete={didChooseAttachments}
        />
        <DownloadChooser modalId={modalDownloadId} objId={objId(node)} />
        <form onSubmit={handleSubmit(submitUpdate)} className='node-form'>
          <input type="hidden" name="utf-8" value="✓" />
          <div className="form-body">
            <div>
              {this.renderFields()}
              <fieldset>
                <h5>Breite</h5>
                {node.nodeType !== NodeTypes.Download && (
                  <Field
                    name="width"
                    z
                    type="radio"
                    value="full"
                    label="Voll"
                    component={renderRadio}
                    handleSubmit={handleSubmit(submitUpdate)}
                  />
                )}
                <Field
                  name="width"
                  type="radio"
                  value="half"
                  label="Halb"
                  component={renderRadio}
                  handleSubmit={handleSubmit(submitUpdate)}
                />
                <Field
                  name="width"
                  type="radio"
                  value="quarter"
                  label="Viertel"
                  component={renderRadio}
                  handleSubmit={handleSubmit(submitUpdate)}
                />

                <hr />
                <Field
                  name="right"
                  type="checkbox"
                  label="Rechts positionieren"
                  component={renderCheckbox}
                  handleSubmit={handleSubmit(submitUpdate)}
                />
                <Field
                  name="clear"
                  type="checkbox"
                  label="Neue Zeile"
                  component={renderCheckbox}
                  handleSubmit={handleSubmit(submitUpdate)}
                />
              </fieldset>
            </div>
          </div>
          <div className="form-footer">
            <button type="button" className="btn btn-danger btn-md" onClick={removeSelectedNode}>
              Element Löschen
            </button>
            <Field name="id" type="hidden" component="input" />
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = createSelector(selectSelectedNode(), selectLinks(), (node, links) => ({
  node,
  links,
  initialValues: node,
  enableReinitialize: true
}))

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    submitUpdate: node => dispatch(updateNode(node)),
    showAttachmentChooser: el => dispatch(showAttachmentChooser(modalId, el)),
    didChooseAttachments: () => dispatch(didChooseAttachments(modalId)),
    showDownloadsChooser: el => dispatch(showDownloadsChooser(modalDownloadId, el)),
    removeLink: (id) => dispatch(removeLink(id)),
    // didChooseDownloads: () => dispatch(didChooseDownloads(modalDownloadId)),
    removeSelectedNode: () => dispatch(removeSelectedNode()),

    updateNodeBody: (id, body, isEng) => dispatch(updateNodeBody(id, body, isEng))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'node'
  })(NodeForm)
)
