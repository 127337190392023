import axios from 'axios'
import Qs from 'qs'
import ReactOnRails from 'react-on-rails'

export const client = (cfg = {}) => axios.create({
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
  responseType: 'json',
  headers: ReactOnRails.authenticityHeaders(),
  ...cfg
})

export const fetch = (page, q) => (
  client().get('/admin/attachments', { params: { page, q: { ...q } } })
)

export const cancelSource = () => axios.CancelToken.source()

export const upload = (file, cancelToken, progressCallback) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = progressEvent.loaded / progressEvent.total
      progressCallback(percentCompleted)
    },
    cancelToken
  }

  const data = new FormData()
  // const title = String(file.name).replace(/\.[^/.]+$/, '')
  // data.append(paramName, token)
  data.append('attachment[file]', file)
  return client().post('/admin/attachments', data, config)
}

export const update = (id, data) => (
  client().patch(`/admin/attachments/${id}`, data)
)

export const updateFile = async (id, file, cancelToken, progressCallback) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = progressEvent.loaded / progressEvent.total
      progressCallback(percentCompleted)
    },
    // cancelToken
  }

  const data = new FormData()
  data.append('attachment[file]', file)
  const res = await client().patch(`/admin/attachments/${id}`, data, config)
  return res
}

export const destroy = (id) => (
  client().delete(`/admin/attachments/${id}`)
)

export const usage = (id) => (
  client().get(`/admin/attachments/${id}/usage`).catch((e) => {
    console.log(e)
  })

)
