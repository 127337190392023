
import { takeLatest, put, select } from 'redux-saga/effects'
import {
  ATTACHMENTS_PAGE_CHANGE,
  ATTACHMENTS_PAGE_CHANGED
} from '../constants'
import { load } from '../actions'
import {
  selectPage,
  selectQ
} from '../selectors'

// worker Saga: will be fired on ATTACHMENTS_PAGE_CHANGED actions
function* pageChange (action) {
  const { page } = action
  // get the page from store
  const currPage = yield select(selectPage())
  // fire page ATTACHMENTS_PAGE_CHANGED
  if (page !== currPage) {
    yield put({ type: ATTACHMENTS_PAGE_CHANGED, page })

    // get the q from store
    const q = yield select(selectQ())
    //  file load page
    yield put(load(page, q))
  }
}

export default function* pageChangeSaga () {
  yield takeLatest(ATTACHMENTS_PAGE_CHANGE, pageChange)
}
