import {
  PAGE_INIT,
  PAGE_ADD_NODE,
  PAGE_MOVE_NODE,
  PAGE_REMOVE_NODE,
  PAGE_REMOVE_SELECTED_NODE,
  PAGE_REQUEST_TOGGLE_NODE,
  PAGE_DESELECT,
  PAGE_UPDATE_NODE,
  PAGE_UPDATE_NODE_IMAGE,
  PAGE_UPDATE_NODE_GALLERY,
  PAGE_UPDATE_NODE_BODY,
  PAGE_SAVE_REQUEST,
  PAGE_SAVE_SUCCESS,
  PAGE_SAVE_FAILED,
  LINKS_INIT,
  PAGE_CHANGE_WIDTH,
  PAGE_MOVE_GALLERY_ITEM,
  PAGE_REMOVE_GALLERY_ITEM,
  PAGE_SHOW_ATTACHMENTS_CHOOSER,
  PAGE_DID_CHOOSE_ATTACHMENTS,
  DOWNLOADS_PAGE_CHANGE,
  DOWNLOADS_FETCH_REQUEST,
  PAGE_SHOW_DOWNLOADS_CHOOSER,
  PAGE_UPDATE_NODE_DOWNLOAD,
  DOWNLOADS_TOGGLE,
  DOWNLOADS_COMMIT_SELECTED,
  LINK_REMOVE
} from './constants'

export const initPage = (pageId, title, nodes) => ({
  type: PAGE_INIT,
  pageId,
  title,
  nodes
})

let _startId = parseInt(new Date().getTime() / 1000 * -1)
const getId = () => _startId--

const newNode = nodeType => {
  return {
    id: getId(),
    nodeType,
    bodyDe: '',
    bodyEn: '',
    formType: 'contact',
    attachments: [],
    width: 'half'
  }
}

export const addNode = nodeType => {
  const node = newNode(nodeType)
  return {
    type: PAGE_ADD_NODE,
    node
  }
}

export const moveNode = (oldIdx, newIdx) => ({
  type: PAGE_MOVE_NODE,
  oldIdx,
  newIdx
})

export const removeNode = id => ({
  type: PAGE_REMOVE_NODE,
  id
})

export const removeSelectedNode = () => ({
  type: PAGE_REMOVE_SELECTED_NODE
})

export const selectNode = id => ({
  type: PAGE_SELECT_NODE,
  id
})

export const toggleNode = id => ({
  type: PAGE_REQUEST_TOGGLE_NODE,
  id
})

export const deselect = () => ({
  type: PAGE_DESELECT
})

export const updateNode = node => ({
  type: PAGE_UPDATE_NODE,
  node
})

export const updateNodeImage = id => ({
  type: PAGE_UPDATE_NODE_IMAGE,
  id
})

export const updateNodeGallery = id => ({
  type: PAGE_UPDATE_NODE_GALLERY,
  id
})

export const updateNodeBody = (id, body, isEnglish) => ({
  type: PAGE_UPDATE_NODE_BODY,
  node: {
    id,
    [`body${isEnglish ? 'En' : 'De'}`]: body
  }
})

export const save = () => ({
  type: PAGE_SAVE_REQUEST
})

export const saveSuccess = nodes => ({
  type: PAGE_SAVE_SUCCESS,
  nodes
})

export const saveFailed = err => ({
  type: PAGE_SAVE_FAILED,
  err
})

export const initLinks = links => ({
  type: LINKS_INIT,
  links
})

export const changeWidth = d => ({
  type: PAGE_CHANGE_WIDTH,
  d
})

export const moveGalleryItem = (dragIndex, hoverIndex) => ({
  type: PAGE_MOVE_GALLERY_ITEM,
  dragIndex,
  hoverIndex
})

export const removeGalleryItem = attachmentId => ({
  type: PAGE_REMOVE_GALLERY_ITEM,
  attachmentId
})

export const showAttachmentChooser = (modalId, cb) => ({
  type: PAGE_SHOW_ATTACHMENTS_CHOOSER,
  modalId,
  cb
})

export const didChooseAttachments = modalId => ({
  type: PAGE_DID_CHOOSE_ATTACHMENTS,
  modalId
})

// load Downloads paged
export const loadDownloadsPage = page => ({
  type: DOWNLOADS_PAGE_CHANGE,
  page
})

// load
export const loadDownloads = (page = 1, q = {}, typeahead = false) => ({
  type: DOWNLOADS_FETCH_REQUEST,
  page,
  q,
  typeahead
})

export const showDownloadsChooser = (modalId, cb) => ({
  type: PAGE_SHOW_DOWNLOADS_CHOOSER,
  modalId,
  cb
})

export const updateNodeDownload = (nodeId, download) => ({
  type: PAGE_UPDATE_NODE_DOWNLOAD,
  nodeId,
  download
})

export const downloadsCommitSelected = download => ({
  type: DOWNLOADS_COMMIT_SELECTED,
  download
})

export const toggleDownload = id => ({
  type: DOWNLOADS_TOGGLE,
  id
})

export const removeLink = id => ({
  type: LINK_REMOVE,
  id
})
