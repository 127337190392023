import searchSaga from './search'
import fetchSaga from './fetch'
import pageChangeSaga from './pageChange'
import deleteSaga from './delete'
import toggleSaga from './toggle'
import {
  addFilesSaga,
  cancelUploadSaga,
  uploadSaga,
  generatePreviewsSaga
} from './upload'
import {updateSaga, updateFileSaga} from './update'

export default [
  searchSaga,
  fetchSaga,
  pageChangeSaga,
  deleteSaga,
  addFilesSaga,
  cancelUploadSaga,
  uploadSaga,
  generatePreviewsSaga,
  updateSaga,
  updateFileSaga,
  toggleSaga
]
