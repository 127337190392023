

import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  ATTACHMENTS_TOGGLE_AND_LOAD_USAGE
} from '../constants'

import { toggle, usageReceived } from '../actions'
import { selectedAttachment, selectUsageById } from '../selectors'
import { usage } from '../api'

function* toggleWorker (action) {
  const { id } = action
  yield put(toggle(id))
  try {
    // get selected attachment
    const atm = yield select(selectedAttachment())
    // get usage
    const attachmentUsage = yield select(selectUsageById(id))

    //  fetch usage
    if (atm !== undefined && attachmentUsage === undefined) {
      // yield delay(350)
      const { data } = yield call(usage, id)
      yield put(usageReceived(id, data))
    }
  } catch (e) {

  }
}

export default function* toggleSaga () {
  yield takeLatest(ATTACHMENTS_TOGGLE_AND_LOAD_USAGE, toggleWorker)
}
